const errorMessages = {
    // Network and API Errors
    NETWORK_ERROR: {
      title: 'Connection Lost',
      message: 'Unable to fetch your advertising data. Please check your internet connection and try again.'
    },
    API_ERROR: {
      title: 'Service Unavailable',
      message: 'We\'re having trouble connecting to our servers. Please try again in a few minutes.'
    },
    TIMEOUT_ERROR: {
      title: 'Request Timeout',
      message: 'The request took too long to complete. Please check your connection and try again.'
    },
  
    // Authentication Errors
    AUTH_ERROR: {
      title: 'Authentication Failed',
      message: 'Your session has expired. Please sign in again to continue.'
    },
    UNAUTHORIZED: {
      title: 'Access Denied',
      message: 'You don\'t have permission to access this feature. Please contact your administrator.'
    },
  
    // Data Errors
    DATA_LOAD_ERROR: {
      title: 'Failed to Load Data',
      message: 'We couldn\'t load your advertising data. Please refresh the page or contact support if the problem persists.'
    },
    DATA_PARSING_ERROR: {
      title: 'Data Processing Error',
      message: 'There was an error processing your advertising data. Our team has been notified.'
    },
  
    // AI-Specific Errors
    AI_SERVICE_ERROR: {
      title: 'AI Service Unavailable',
      message: 'We\'re unable to generate AI recommendations right now. Our team has been notified and is working to restore service.'
    },
    AI_GENERATION_ERROR: {
      title: 'Generation Failed',
      message: 'We couldn\'t generate AI suggestions. Please try again'
    },
    AI_RATE_LIMIT: {
      title: 'Rate Limit Reached',
      message: 'You\'ve reached the maximum number of AI generations for today. Please try again in 24 hours.'
    },
  
    // Validation Errors
    INVALID_INPUT: {
      title: 'Invalid Input',
      message: 'Please check your input values and try again.'
    },
    MISSING_DATA: {
      title: 'Missing Information',
      message: 'Some required information is missing. Please fill in all required fields.'
    },
  
    // Business Logic Errors
    CAMPAIGN_LIMIT: {
      title: 'Campaign Limit Reached',
      message: 'You\'ve reached the maximum number of active campaigns for your plan.'
    },
    BUDGET_EXCEEDED: {
      title: 'Budget Limit Exceeded',
      message: 'The proposed changes would exceed your campaign budget limit.'
    },
  
    // Reports Errors
    SHEET_UPLOAD_FAILED: {
      title: 'Upload Failed',
      message: 'Unable to upload data to sheet. Please try again later'
    },

    // Reports Errors
    SAVING_FAILED: {
      title: 'Saving Failed',
      message: 'The report could not be saved. Please try again later'
    },

    // Grouping Errors
    GROUP_FETCH_FAILED: {
      title: 'Group Fetch Failed',
      message: 'Unable to fetch group data. Please try again later'
    },
    GROUP_SAVE_FAILED: {
      title: 'Group Not Saved',
      message: 'Failed to save groups. Please try again later'
    },
    
    // Reports Errors
    FETCH_REPORT_FAILED: {
      title: 'Report Fetch Failed',
      message: 'Unable to fetch the report. Please try again later.'
    },

    // Generic Errors
    UNKNOWN_ERROR: {
      title: 'Something Went Wrong',
      message: 'An unexpected error occurred. Please try again or contact support if the problem persists.'
    },
    MAINTENANCE_ERROR: {
      title: 'System Maintenance',
      message: 'The system is currently undergoing maintenance. Please try again in a few minutes.'
    }
  };
  
export default errorMessages;