import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import delete_icon from "../images/bin.png";

const CreativeAnalysisSetting = ({ViewedPage, shop_domain, app_url}) => {
    ViewedPage(17)

    const [loading, setLoading] = useState(true)
    const [grouping_search_filter_val, setGroupingSearchFilterVal] = useState("")
    const [grouping_name, setGroupingName] = useState("")
    const [custom_metrics_name, setCustomMetricsName] = useState("")
    const [current_grouping_arr, setCurrentGrouping] = useState([])
    const [all_grouping_data, setGroupingData] = useState({})

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                // Facebook Loader data
                axios.post(app_url+"/api/facebook/meta_creative_settings",{
                    shop_domain:Cookies.get('shop')
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }

    }, [app_url, shop_domain])


    const handle_grouping_add = (e) => {
        e.preventDefault();
        let grouping_search_filter = document.getElementById("grouping_search_filter").value;
        let temp_current_grouping_arr = [...current_grouping_arr, [grouping_search_filter.split("||")[0].replaceAll("_"," "),grouping_search_filter.split("||")[1].replaceAll("_"," "),grouping_search_filter_val]];
        setCurrentGrouping(temp_current_grouping_arr)
    }
    
    
    const handle_grouping_clear = (e) => {
        e.preventDefault();
        setGroupingName("")
        setGroupingSearchFilterVal("")
        setCurrentGrouping([])
    }
    
    
    const handle_grouping_remove = (key) => {
        let temp_all_grouping_data = {...all_grouping_data};
        delete temp_all_grouping_data[key]
        setGroupingData(temp_all_grouping_data);
    }


    const handle_grouping_submit = (e) => {
        e.preventDefault();
        if((grouping_name.trim() === "") || (grouping_name === null) || (grouping_name === undefined)){
            toast.error("Group name cannot be empty")
        }
        if((grouping_name.trim() === "") || (grouping_name === null) || (grouping_name === undefined)){
            toast.error("Group name already exists")
        }
        if((current_grouping_arr.length === 0) || (current_grouping_arr === null) || (current_grouping_arr === undefined)){
            toast.error("Please add atleast one grouping")
        }
        else{
            let temp_all_grouping_data = {...all_grouping_data};
            temp_all_grouping_data[grouping_name] = current_grouping_arr
            console.log(temp_all_grouping_data)
            setGroupingData(temp_all_grouping_data)
            handle_grouping_clear(e)
        }
    }

    return (
        <div className='main-container'>
        {!shop_domain?
            <div className='go_to_shopify_admin_div'>
                <h6 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h6>
            </div>
            :
            <>
                { !loading ?
                    <>
                        <div className='main-title mt-4'>
                            <h2>Meta Creative Settings</h2>
                        </div>

                        <div className='main-accordion-body mt-4 p-2'>
                            <Accordion defaultActiveKey={"1"}>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b style={{marginLeft:"10px"}}>Grouping</b></Accordion.Header>
                                    <Accordion.Body>                        
                                        <Container className='my-4 mx-2 px-3' style={{ backgroundColor:"white", width:"98%", borderRadius:"0.5rem"}}>
                                            <Row className='justify-content-md-left' lg={2} xs={1}>
                                                <Col className='px-3'>
                                                    <div style={{height:"100%", boxShadow: "rgba(17, 44, 123, 0.1) 10px 15px 15px",border: "2px solid #c5c5c5",borderRadius:"0.5rem", padding:"2rem"}}>
                                                        <Col>
                                                            <FloatingLabel controlId="floatingInput" label="Group Name" className="mb-3">
                                                                <Form.Control type="text" placeholder="Group Name" value={grouping_name} onChange={(e) => {
                                                                    setGroupingName(e.target.value);
                                                                }} />
                                                            </FloatingLabel>
                                                        </Col>

                                                        <Row className='justify-content-md-left' lg={2} xs={1}>
                                                            <Col>
                                                                <FloatingLabel controlId="floatingInput" label="Type to filter..." className="mb-3">
                                                                    <Form.Control className="mb-3" type="text" placeholder="Type to filter..." value={grouping_search_filter_val} onChange={(e)=>{
                                                                        setGroupingSearchFilterVal(e.target.value);
                                                                    }} />
                                                                </FloatingLabel>
                                                            </Col>

                                                            <Col>
                                                                <FloatingLabel controlId="floatingInput" label="Filter" className="mb-3">
                                                                    <Form.Select id='grouping_search_filter' >
                                                                        <option value={`Campaign_name||contains||${grouping_search_filter_val}`}>Campaign name contains {grouping_search_filter_val}</option>
                                                                        <option value={`Adset_name||contains||${grouping_search_filter_val}`}>Adset name contains {grouping_search_filter_val}</option>
                                                                        <option value={`Ad_name||contains||${grouping_search_filter_val}`}>Ad name contains {grouping_search_filter_val}</option>
                                                                        <option value={`Campaign_name||does_not_contain||${grouping_search_filter_val}`}>Campaign name does not contain {grouping_search_filter_val}</option>
                                                                        <option value={`Adset_name||does_not_contain||${grouping_search_filter_val}`}>Adset name does not contain {grouping_search_filter_val}</option>
                                                                        <option value={`Ad_name||does_not_contain||${grouping_search_filter_val}`}>Ad name does not  contains {grouping_search_filter_val}</option>
                                                                    </Form.Select>
                                                                </FloatingLabel>
                                                            </Col>
                                                        </Row>   

                                                        <Col className="mb-3">
                                                            <Button variant='primary' onClick={handle_grouping_add}>Add</Button>
                                                            <Button className='mx-3' variant='danger' onClick={handle_grouping_clear}>Clear</Button>
                                                        </Col>

                                                        <Col className="mb-3">
                                                            <span>{grouping_name} = {current_grouping_arr.map((val) => {
                                                                return (
                                                                    val.join(" ")
                                                                )
                                                            }).join(" and ")}</span>
                                                        </Col>

                                                        <Col className="mb-3">
                                                            <Button variant='primary' onClick={handle_grouping_submit}>Submit</Button>
                                                        </Col>
                                                    </div>
                                                </Col>

                                                <Col className='px-3'>
                                                    <div style={{height:"22rem", boxShadow: "rgba(17, 44, 123, 0.1) 10px 15px 15px",border: "2px solid #c5c5c5",borderRadius:"0.5rem", padding:"2rem", overflowY:"scroll"}}>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Group Name</th>
                                                                    <th>Filter</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(all_grouping_data).map((key) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{key}</td>
                                                                            <td>{all_grouping_data[key].map((val) => {return val.join(" ")}).join(" and ")}</td>
                                                                            <td onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handle_grouping_remove(key);
                                                                            }}><img src={delete_icon} width={20} alt='Delete'/></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Accordion.Body>    
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><b style={{marginLeft:"10px"}}>Custom Metric(s)</b></Accordion.Header>
                                    <Accordion.Body>                        
                                        <Container className='my-4 mx-2 px-3' style={{ backgroundColor:"white", width:"98%", borderRadius:"0.5rem"}}>
                                            <Row className='justify-content-md-left' lg={2} xs={1}>
                                                <Col className='px-3'>
                                                    <div style={{height:"22rem", boxShadow: "rgba(17, 44, 123, 0.1) 10px 15px 15px",border: "2px solid #c5c5c5",borderRadius:"0.5rem", padding:"2rem"}}>
                                                        <Col>
                                                            <FloatingLabel controlId="floatingInput" label="Custom Metric(s) Name" className="mb-3">
                                                                <Form.Control type="text" placeholder="Custom Metric(s) Name" value={custom_metrics_name} onChange={(e) => {
                                                                    setCustomMetricsName(e.target.value);
                                                                }} />
                                                            </FloatingLabel>
                                                        </Col>

                                                        <Row className='justify-content-md-left' lg={2} xs={1}>
                                                            <Row className='align-items-center justify-content-md-left mb-3' lg={1} xs={1}>
                                                                <Col>
                                                                    <FloatingLabel controlId="floatingInput" label="Filter">
                                                                        <Form.Select id='select_metrics_dropdown' >
                                                                            {["clicks","impressions","spend","unique_video_continuous_2_sec_watched_actions_video_view","video_15_sec_watched_actions_video_view","video_30_sec_watched_actions_video_view","video_avg_time_watched_actions_video_view","video_continuous_2_sec_watched_actions_video_view","video_p100_watched_actions_video_view","video_p25_watched_actions_video_view","video_p50_watched_actions_video_view","video_p75_watched_actions_video_view","video_p95_watched_actions_video_view","video_thruplay_watched_actions_video_view","video_view"].map((val) => {
                                                                                return (
                                                                                    <option value={val}>{val.replace("video_","").replaceAll("_watched_actions","").split("_").join(" ")}</option>
                                                                                )
                                                                            })}
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Col>
                                                            </Row>
                                                            <Row className='align-items-center justify-content-md-left mb-3' lg={4} xs={1}>
                                                                <Col>
                                                                    <Button variant='secondary'>+</Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button variant='secondary'>-</Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button variant='secondary'>*</Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button variant='secondary'>/</Button>
                                                                </Col>
                                                            </Row>   
                                                        </Row>   

                                                        <Col className="mb-3">
                                                            <Button variant='primary'>Add</Button>
                                                            <Button className='mx-3' variant='danger'>Clear</Button>
                                                        </Col>

                                                        <Col className="mb-3">
                                                            <span>{grouping_name} = {current_grouping_arr.map((val) => {
                                                                return (
                                                                    val.join(" ")
                                                                )
                                                            }).join(" and ")}</span>
                                                        </Col>

                                                        <Col className="mb-3">
                                                            <Button variant='primary'>Submit</Button>
                                                        </Col>
                                                    </div>
                                                </Col>

                                                <Col className='px-3'>
                                                    <div style={{height:"100%", boxShadow: "rgba(17, 44, 123, 0.1) 10px 15px 15px",border: "2px solid #c5c5c5",borderRadius:"0.5rem", padding:"2rem"}}>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Custom Metrics</th>
                                                                    <th>Formula</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys({}).map((key) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{key}</td>
                                                                            <td>{all_grouping_data[key].map((val) => {return val.join(" ")}).join(" and ")}</td>
                                                                            <td onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handle_grouping_remove(key);
                                                                            }}><img src={delete_icon} width={20} alt='Delete'/></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Accordion.Body>    
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </>
                    :
                    <div className='main_conatiner_spinner'>
                        <Spinner animation="border"/>
                    </div>
                }
            </>
        }
        </div>
    );
}

export default CreativeAnalysisSetting