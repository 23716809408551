import toast from 'react-hot-toast';

export const showToast = {
    success: (message) => {
        toast.dismiss();
        toast.success(message);
    },
    
    error: (message) => {
        toast.dismiss();
        toast.error(message);
    },

};