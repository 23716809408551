import { useState } from 'react'
import './App.css'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import Home from './pages/Home'
import EnrichAndBid from './pages/EnrichAndBid'
import LifetimeValue from './pages/LifetimeValue'
import MetricsDeepdive from './pages/MetricsDeepdive'
import MetricsPerformance from './pages/MetricsPerformance'
import CustomerBehavior from './pages/CustomerBehavior'
import ProductPerformance from './pages/ProductPerformance'
import FeedEnrichment from './pages/ProductCataloging'
import ProductCRO from './pages/ProductCRO'
import AdsCopyOptimization from './pages/AdsCopyOptimization'
import RepurchaseRate from './pages/RepurchaseRate'
import SegmentBestseller from './pages/SegmentBestseller'
import ProductAudit from './pages/ProductAudit'
import MetaAdsShopping from './pages/MetaAdsShopping'
import MetaCreativeGeneration from './pages/MetaCreativeGeneration'
import MetaCreativeAnalysis from './pages/CreativeAnalysis'
import CreativeAnalysisSetting from './pages/CreativeAnalysisSetting'
import Reports from './pages/Reports'
import Groups from './pages/Groups'
import ReportTopPerforming from './pages/ReportTopPerforming'
import ReportComparativeAnalysis from './pages/ReportComparativeAnalysis'
import LandingPageOptimization from './pages/LandingPageOptimization'
import ChatBotUI from './pages/ChatBotUI'
import GoogleAdsCopySS from './pages/GoogleAdsCopySS'
import Login from './pages/login';
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Cookies from 'js-cookie';

function App() {
  const [shop_domain, setShopDomain] = useState(Cookies.get('shop'));
  const [shop_name, setShopName] = useState(Cookies.get('shop_name'));
  const stores = (Cookies.get('stores')) ? JSON.parse(Cookies.get('stores')) : [];
  const allowed_pages_json = (Cookies.get('allowed_pages_json')) ? JSON.parse(Cookies.get('allowed_pages_json')) : {};
  const show_under_dev_pages = Cookies.get('show_under_dev_pages');
  // const app_url = "https://insights.digital-mozarts.com";
  let app_url = "http://localhost:9000";
  if(process.env.REACT_APP_BASE_URL !== null && process.env.REACT_APP_BASE_URL !== undefined){
    app_url = process.env.REACT_APP_BASE_URL;
  }


  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)
  
  var temp_selected_page = 0;
  if(sessionStorage.getItem("page_info")){
    temp_selected_page = Number(sessionStorage.getItem("page_info"));
  }

  const [selectedPage, setSelectedPage] = useState(temp_selected_page)
  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  const ViewedPage = (value) => {
    setTimeout(() => {
      sessionStorage.setItem("page_info",value)
      setSelectedPage(value)
    },100)
  }

  const changeStoreDomain = (value) => {
    setShopDomain(value)
  }

  const changeStoreName = (value) => {
    setShopName(value)
  }

  const queryParameters = new URLSearchParams(window.location.search)

  if((!shop_domain) && (!queryParameters.get('shop'))){
    return <Login app_url={app_url} />;
  }

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} shop_domain={shop_domain}/>
      <Sidebar app_url={app_url} openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} selectedPage={selectedPage} stores={stores} changeStoreDomain={changeStoreDomain} changeStoreName={changeStoreName} shop_domain={shop_domain} ShopName={shop_name} allowed_pages_json={allowed_pages_json} show_under_dev_pages={show_under_dev_pages} />
      <Toaster/>
      <Routes>
        <Route path="/metrics-performance" element={<MetricsPerformance ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/metrics-deepdive" element={<MetricsDeepdive ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/lifetime-value" element={<LifetimeValue ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/repurchase-rate" element={<RepurchaseRate ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/product-journey" element={<CustomerBehavior ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/product-performance" element={<ProductPerformance ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/feed-enrichment" element={<FeedEnrichment ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/product-cro" element={<ProductCRO ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/ads-copy-optimization" element={<AdsCopyOptimization ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/segment-bestseller" element={<SegmentBestseller ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/all-product-audit" element={<ProductAudit ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/shopping" element={<MetaAdsShopping ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/creative_ideation" element={<MetaCreativeGeneration ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/creative-analysis" element={<MetaCreativeAnalysis ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/landing-page-optimization" element={<LandingPageOptimization ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/chatbot" element={<ChatBotUI ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/google-ads-copy-ss" element={<GoogleAdsCopySS ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/" element={<Home ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/reports" element={<Reports ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/groups" element={<Groups ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/meta-creative-settings" element={<CreativeAnalysisSetting ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/report/top_performing/:report_id" element={<ReportTopPerforming ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/report/comparative_analysis/:report_id" element={<ReportComparativeAnalysis ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
        <Route path="/enrich-and-bid" element={<EnrichAndBid ViewedPage={ViewedPage} shop_domain={shop_domain} app_url={app_url}/>}></Route>
      </Routes>
    </div>
  )
}

export default App