import React from 'react'
import {BsJustify} from 'react-icons/bs'
import scalex_ai_logo from "../images/scalex_ai_logo.png"

function Header({OpenSidebar}) {
  return (
    <header className='header'>
        <div className='menu-icon'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>
          
        <div className="header-title">
          <img src={scalex_ai_logo} alt='logo' width={40} className='icon_header'/> Value Bidder
        </div>
    </header>
  )
}

export default Header
