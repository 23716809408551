import React from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';


function SegmentBestseller({ViewedPage, shop_domain, app_url}) {
    ViewedPage(11);
    
    const loading = false;

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Segment Best Sellers</h2>
                            </div>

                            <Accordion className='mt-4' defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <b style={{marginLeft:"10px"}}>By Channel</b>
                                    </Accordion.Header>
                                    <Accordion.Body style={{padding:"0 !important"}}>
                                        <Chart
                                        chartType="Table"
                                        width="100%"
                                        data={channel_data}
                                        options={{
                                            width: '100%',
                                            curveType: "function",
                                            legend: { position: "bottom" },
                                            cssClassNames:{
                                                tableCell :"channel_performance_table_cell",
                                                headerCell :"channel_performance_header_cell",
                                                tableRow :"channel_performance_table_row", 
                                                oddTableRow :"channel_performance_old_table_row"
                                            }
                                        }}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <b style={{marginLeft:"10px"}}>By Customer Type</b>
                                    </Accordion.Header>
                                    <Accordion.Body style={{padding:"0 !important"}}>
                                        <Chart
                                        chartType="Table"
                                        width="100%"
                                        data={customer_type_data}
                                        options={{
                                            width: '100%',
                                            curveType: "function",
                                            legend: { position: "bottom" },
                                            cssClassNames:{
                                                tableCell :"channel_performance_table_cell",
                                                headerCell :"channel_performance_header_cell",
                                                tableRow :"channel_performance_table_row", 
                                                oddTableRow :"channel_performance_old_table_row"
                                            }
                                        }}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <b style={{marginLeft:"10px"}}>By City</b>
                                    </Accordion.Header>
                                    <Accordion.Body style={{padding:"0 !important"}}>
                                        <Chart
                                        chartType="Table"
                                        width="100%"
                                        data={city_data}
                                        options={{
                                            width: '100%',
                                            curveType: "function",
                                            legend: { position: "bottom" },
                                            cssClassNames:{
                                                tableCell :"channel_performance_table_cell",
                                                headerCell :"channel_performance_header_cell",
                                                tableRow :"channel_performance_table_row", 
                                                oddTableRow :"channel_performance_old_table_row"
                                            }
                                        }}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default SegmentBestseller

const channel_data = [
    ["Channel","Product 1","Product 2","Product 3", "Product 4","Product 5","Product 6"],
    ["Google","UM CLASSIC WAYFARER // 002","CRUISER // 004","AIRPLANE MODE // 006", "90’S KID Style Cap","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies"],
    ["Meta","AIRPLANE MODE // 006","UM CLASSIC WAYFARER // 002","90’S KID Style Cap", "CRUISER // 004","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies"],
    ["Bing","CRUISER // 004","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","AIRPLANE MODE // 006", "90’S KID Style Cap","Harry Potter: The Houses Men Oversized Hoodies","UM CLASSIC WAYFARER // 002"],
    ["Email","AIRPLANE MODE // 006","CRUISER // 004","UM CLASSIC WAYFARER // 002", "90’S KID Style Cap","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies"],
    ["Direct","AIRPLANE MODE // 006","UM CLASSIC WAYFARER // 002","CRUISER // 004", "90’S KID Style Cap","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies"],
    ["Organic","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies","90’S KID Style Cap", "AIRPLANE MODE // 006","UM CLASSIC WAYFARER // 002","CRUISER // 004"],
];


const customer_type_data = [
    ["Customer Type","Product 1","Product 2","Product 3", "Product 4","Product 5","Product 6"],
    ["New Customers","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","90’S KID Style Cap","UM CLASSIC WAYFARER // 002", "Harry Potter: The Houses Men Oversized Hoodies","CRUISER // 004","AIRPLANE MODE // 006"],
    ["Repeated Customers","Harry Potter: The Houses Men Oversized Hoodies","90’S KID Style Cap","Harry Potter: The Houses Men Oversized Hoodies", "UM CLASSIC WAYFARER // 002","CRUISER // 004","AIRPLANE MODE // 006"],
];

const city_data = [
    ["City","Product 1","Product 2","Product 3", "Product 4","Product 5","Product 6"],
    ["Indore","Harry Potter: The Houses Men Oversized Hoodies","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","90’S KID Style Cap", "AIRPLANE MODE // 006","CRUISER // 004","UM CLASSIC WAYFARER // 002"],
    ["Mumbai","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","90’S KID Style Cap","Harry Potter: The Houses Men Oversized Hoodies", "AIRPLANE MODE // 006","UM CLASSIC WAYFARER // 002","CRUISER // 004"],
    ["Hyderabad","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","AIRPLANE MODE // 006","CRUISER // 004", "90’S KID Style Cap","Harry Potter: The Houses Men Oversized Hoodies","UM CLASSIC WAYFARER // 002"],
    ["Surat","90’S KID Style Cap","Harry Potter: The Houses Men Oversized Hoodies","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts", "AIRPLANE MODE // 006","CRUISER // 004","UM CLASSIC WAYFARER // 002"],
    ["Pune","90’S KID Style Cap","UM CLASSIC WAYFARER // 002","CRUISER // 004", "AIRPLANE MODE // 006","Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts","Harry Potter: The Houses Men Oversized Hoodies"],
];
// const channel_data = [
//     ["Channel","Product","Orders","Revenue (in INR)"],
//     ["Google","Product 1","122","2,00,0084.45"],
//     ["Meta","Product 2","93","1,66,247.62"],
//     ["Bing","Product 3","21","40,429.48"],
// ];

// const customer_type_data = [
//     ["Customer Type","Product","Orders","Revenue (in INR)"],
//     ["New Customer","Product 1","159","2,36,687.01"],
//     ["Repeated Customer","Product 2","124","1,89,729.48"],
// ];

// const city_data = [
//     ["City","Product","Orders","Revenue (in INR)"],
//     ["Indore","Product 1","101","1,27,908.01"],
//     ["Mumbai","Product 2","99","1,15,888.18"],
//     ["Hyderabad","Product 3","85","1,00,001.99"],
//     ["Surat","Product 4","79","98,745.11"],
//     ["Pune","Product 5","77","89,632.96"],
// ];