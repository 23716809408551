import React, {useState,useEffect} from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import FormSelect from 'react-bootstrap/FormSelect';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import alasql from 'alasql';


alasql.fn.ReplaceNullValues = function(val){
  return ((val === Infinity) || (val === undefined)) ? 0 : val;
}

alasql.fn.Add_Image_HTML_tag = function(val){
  return `<img src="${val}" alt="Image" width=50 height=50> </img>`;
}

const all_scattered_charts_type = {
  "Keyword": [
    { value: 'Cost', label: 'Cost' },
    { value: 'Impr.', label: 'Impr' },
    { value: 'Interactions', label: 'Interactions' },
    { value: 'Conv. value', label: 'Conv. value' },
    { value: 'Conversions', label: 'Conversions' },
  ],
  "Ad group": [
    { value: 'Cost', label: 'Cost' },
    { value: 'Impr.', label: 'Impr' },
    { value: 'Clicks', label: 'Clicks' },
    { value: 'Conversions', label: 'Conversions' },
  ],
}

function LandingPageOptimization({ViewedPage, shop_domain, app_url}) {
    ViewedPage(10);

    const [loading, setLoading] = useState(false);
    const [analyze_btn_loading, setAnalyzeBtnLoading] = useState(false);
    const [generate_btn_loading, setGenerateBtnLoading] = useState(false);
    const [show_landing_page, setShowLandingPageURL] = useState(false);
    const [title_x_axis, setTitleXAxis] = useState({"value":"Cost","label":"Cost"});
    const [title_y_axis, setTitleYAxis] = useState({"value":"Conversions","label":"Conversions"});
    const [x_axis, setXAxis] = useState({"value":"Cost","label":"Cost"});
    const [y_axis, setYAxis] = useState({"value":"Conversions","label":"Conversions"});
    const [price_range, setPriceRange] = useState([["All","All"]]);
    const [landing_page_exp, setAdStrengthValue] = useState("All");
    const [scattered_plot_type, setScatteredPlotType] = useState("Keyword");
    const [all_keywords, setAllKeywords]  = useState(get_all_keywords(ad_group_level_data));
    const [selected_keywords, setSelectedKeywords] = useState(all_keywords[0]);
    const [scatter_overview_data, setScatterOverviewData] = useState(get_scatter_overview_data(keyword_level_data, x_axis["value"], y_axis["value"], price_range, landing_page_exp, scattered_plot_type));
    const [suggestions, setSuggestions] = useState([]);
    const [selected_suggestion, setSelectedSuggestion] = useState("");
    const [table_data_level, setTableDataLevel] = useState("Ad group");
    const [custom_input, setCustomInput] = useState("");

    const [current_metric_options, setCurrentMetricOptions] = useState(all_scattered_charts_type[scattered_plot_type]);

    const handleSuggestionSelect = ({chartWrapper}) => {
      const chart = chartWrapper.getChart();
      const selection = chart.getSelection();
      if (selection.length === 0) return;
      const rowIndex = selection[0].row;
      console.log(suggestions[rowIndex]);
      setSelectedSuggestion(suggestions[rowIndex]);
    };

  
    const handleSaveDescriptionToSheet = (e) => {
      e.preventDefault();
      setGenerateBtnLoading(true);
      try {
        if(Cookies.get('shop')){
            axios.post(app_url+"/api/shopify/send_landing_page_to_sheet",{
              sheet_data: ((custom_input.trim() !== "") && (custom_input !== null) && (custom_input !== undefined)) ? [custom_input] : selected_suggestion,
            }, {withCredentials:true})
            .then(request => request.data)
            .then(data => {
                setTimeout(() => {
                  toast.success("Added")
                  setShowLandingPageURL(true);
                  setGenerateBtnLoading(false);
                }, 30000)
            })
            .catch(err => {
              setGenerateBtnLoading(false);
                toast.error("No Data to Show.");
                console.log(err)
              });
            }
          }
      catch(err){
        setGenerateBtnLoading(false);
          toast.error("No Data to Show.");
          console.log(err)
      }
    }

    const handleAnalyze = (e) => {
      e.preventDefault();
      setAnalyzeBtnLoading(true);
      try{
          axios.post(app_url+"/chats",{
              new_message:`Keyword : ${selected_keywords} \n\n Using above details, Provide 5 ad copies headlines in less than 30 characters. Make sure 2 headline follows intent of above keyword. And the other 3 headlines use different communication themes. Response should in json format. Example, {"Headline":["Headline 1","Headline 2","Headline 3","Headline 4","Headline 5"]}.`, 
              old_message:[],
              format:"json_object"
          }, {withCredentials:true})
          .then(res => res.data)
          .then(data => {
              console.log(JSON.parse(data.data))
              let temp_suggestions_key = Object.keys(JSON.parse(data.data))[0];
              let temp_suggestions = [[["Headline Suggestions"]] , JSON.parse(data.data)[temp_suggestions_key].map((val) => { return [val] })].flat();
              console.log("Suggestion",temp_suggestions)
              setSuggestions(temp_suggestions)
              setAnalyzeBtnLoading(false)
          })
          .catch(err => {
              setAnalyzeBtnLoading(false)
              toast.error("Please try after some time.");
              console.log(err);
          })
      }
      catch(error){
          toast.error("Please try after some time.");
          console.log(error);
      }
  }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Ad Copy Optimizations</h2>
                            </div>

                            <Container className='my-4'>
                                <Row className='justify-content-md-left' lg={6} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Data Level</Label>
                                        <ReMultiSelect 
                                            id="data_level"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: scattered_plot_type, label: scattered_plot_type }}
                                            options={[
                                              { value: "Keyword", label: "Keyword" },
                                              { value: "Ad group", label: "Ad group" },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setScatteredPlotType(selected_vals.value);
                                              setCurrentMetricOptions(all_scattered_charts_type[selected_vals.value]);
                                              setXAxis({"value":"Cost","label":"Cost"});
                                              setYAxis({"value":"Conversions","label":"Conversions"});
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">X-Axis</Label>
                                        <ReMultiSelect 
                                            id="x_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={x_axis}
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setXAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Y-Axis</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={y_axis}
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setYAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Cost Filter</Label>
                                        <ReMultiSelect 
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={[{ value: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All", label: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All" }]}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: '0-50', label: '0-50' },
                                                { value: '50-100', label: '50-100' },
                                                { value: '100-150', label: '100-150' },
                                                { value: '150-200', label: '150-200' },
                                                { value: '200-250', label: '200-250' },
                                                { value: '250-300', label: '250-300' },
                                                { value: '300-350', label: '300-350' },
                                                { value: '350-400', label: '350-400' },
                                                { value: '400-450', label: '400-450' },
                                                { value: '450-500', label: '450-500' },
                                                { value: '500-100000', label: '500+' },
                                            ]}
                                            onChange={(selected_vals) => {
                                                var temp_list = [];
                                                if(selected_vals.value !== "all"){
                                                    temp_list.push([selected_vals.value.split('-')[0], selected_vals.value.split('-')[1]]);
                                                    setPriceRange(temp_list);
                                                }
                                                else{
                                                    setPriceRange(["all","all"]);
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Ad relevance</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: landing_page_exp, label: landing_page_exp }}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: 'Above average', label: 'Above average' },
                                                { value: 'Below average', label: 'Below average' },
                                                { value: 'Average', label: 'Average' },
                                                { value: '--', label: '--' },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setAdStrengthValue(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={() => {
                                            setLoading(true);
                                            setTimeout(()=> {
                                              setTitleXAxis(x_axis)
                                              setTitleYAxis(y_axis)
                                              setScatterOverviewData(get_scatter_overview_data(scattered_plot_type === "Keyword" ? keyword_level_data : ad_group_level_data, x_axis["value"], y_axis["value"], price_range, landing_page_exp, scattered_plot_type))
                                              setLoading(false);
                                            }, 500)
                                        }}>Apply</Button>
                                    </Col>
                                </Row>
                            </Container>

                            <div className='cro-overview mb-5'>
                                { scatter_overview_data.length > 0 ?
                                    <Chart
                                        chartType="ScatterChart"
                                        width="100%"
                                        height="500px"
                                        data={scatter_overview_data}
                                        options={{
                                            pointSize:10,
                                            chartArea: {
                                                width: "70%",
                                                height: "80%"
                                            },
                                            hAxis: { 
                                                title:title_x_axis["value"],
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            vAxis: {
                                              title:title_y_axis["value"],
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            curveType: "function",
                                            tooltip: { isHtml: true },
                                            legend: {
                                              alignment: 'center',
                                              
                                            },
                                            series: [
                                              { color: 'darkgreen', labelInLegend: 'Above average' },
                                              { color: 'red', labelInLegend: 'Below average' },
                                              { color: 'yellow', labelInLegend: 'Average' },
                                              { color: 'orange', labelInLegend: '--' },
                                            ]
                                        }}
                                    />
                                    :
                                    "No data to Show."
                                }
                            </div>

                            <Container className='my-4'>
                              <Row className='justify-content-md-left' lg={3} xs={1}>
                                <Col className='p-2'>
                                  <Label className="charts_filter_label">Data Level</Label>
                                  <ReMultiSelect 
                                      className='popup_filter_include_exclude'
                                      closeMenuOnSelect={true}
                                      components={ReMultiSelectAnimated()}
                                      value={{ value: table_data_level, label: table_data_level }}
                                      options={[
                                        { value: "Keyword", label: "Keyword" },
                                        { value: "Ad group", label: "Ad group" },
                                      ]}
                                      onChange={(selected_vals) => {
                                        setTableDataLevel(selected_vals.value);
                                        let temp_keywords = get_all_keywords(selected_vals.value === "Keyword" ? keyword_level_data : ad_group_level_data);
                                        setAllKeywords(temp_keywords)
                                        setSelectedKeywords(temp_keywords[0])
                                      }}
                                  />
                                </Col>
                                <Col className='p-2'>
                                  <Label className="charts_filter_label">Keywords</Label>
                                  <ReMultiSelect 
                                      id="select_keyword"
                                      className='popup_filter_include_exclude'
                                      closeMenuOnSelect={true}
                                      components={ReMultiSelectAnimated()}
                                      value={{ value: selected_keywords, label: selected_keywords }}
                                      options={all_keywords.map((val) => { return { value: val, label: val }})}
                                      onChange={(selected_vals) => {
                                        setSelectedKeywords(selected_vals.value);
                                      }}
                                  />
                                </Col>
                                <Col className='p-2 d-flex align-items-end'>
                                  {analyze_btn_loading ?
                                    <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}}><Spinner animation="border"/></Button>
                                    :
                                    <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handleAnalyze}>Analyze</Button>
                                  }
                              </Col>
                            </Row>

                            {suggestions.length > 0 &&
                              <>
                                <Row className='justify-content-md-left mt-3' lg={1} xs={1}>
                                  <Chart
                                    chartType="Table"
                                    width="100%"
                                    data={suggestions}
                                    chartEvents={[
                                      {
                                        eventName: 'select',
                                        callback: handleSuggestionSelect,
                                      },
                                    ]}
                                    options={{
                                        width: '100%',
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                        allowHtml: true,
                                        cssClassNames:{
                                            tableCell :"ad_copy_optimization_table_cell",
                                            headerCell :"ad_copy_optimization_header_cell",
                                            tableRow :"ad_copy_optimization_table_row", 
                                            selectedTableRow  : "ad_copy_optimization_selected_table_row",
                                            hoverTableRow : "ad_copy_optimization_hover_table_row",
                                            oddTableRow :"ad_copy_optimization_table_row" 
                                        }
                                    }}
                                  />
                                </Row>
                              </>
                            }
                          </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default LandingPageOptimization


function jsonToArrayObject(res, graph_columns) {
  var columns = Object.keys(res[0]);
  var resultArray = res.map(function (obj) {
    var res_arr = [];
    for (let column_name of columns) {
      res_arr.push(obj[column_name]);
    }
    return res_arr;
  });
  resultArray.unshift(graph_columns)
 return resultArray;
}


function get_all_keywords(keyword_level_data){
  var res = alasql(`SELECT ARRAY(DISTINCT Keyword) as all_keywords FROM ? WHERE ([Ad relevance] in ('Average','Below average')) AND ([Campaign] NOT LIKE '%competition%') AND ([Ad group] NOT LIKE '%competition%') AND ([Campaign] NOT LIKE '%Competition%') AND ([Ad group] NOT LIKE '%Competition%') AND ([Keyword] NOT LIKE '%Plan B%') AND ([Keyword] NOT LIKE '%plan b%') AND ([Keyword] NOT LIKE '%Plan b%') AND ([Keyword] NOT LIKE '%plan B%')`, [keyword_level_data]);
  console.log(res[0]["all_keywords"]);
  return res[0]["all_keywords"];
}


function get_scatter_overview_data(keyword_level_data, x_axis, y_axis, price_range, landing_page_exp, scattered_plot_type){
    var filtered_data = keyword_level_data;

    if(price_range.length > 0){
      for(let price of price_range){
        if( price[0] !== "All"){
          filtered_data = alasql(`SELECT * FROM ? WHERE (([Cost] >= ${price[0]}) AND ([Cost] <= ${price[1]}))`, [filtered_data]);
        }
      }
    }
    
    if(landing_page_exp !== "All"){
      filtered_data = alasql(`SELECT * FROM ? WHERE [Ad relevance] = '${landing_page_exp}'`, [filtered_data]);
    }
    

    var res = alasql(`SELECT * FROM ?`, [filtered_data]);
    if(res.length > 0){
      var final_res = [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]]
      for(let obj of res ){
          final_res.push([
              parseFloat(obj[x_axis]),
              
              obj["Ad relevance"] === "Above average" ? parseFloat(obj[y_axis]) : null,
              `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Ad group"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Ad relevance : <b>${obj["Ad relevance"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["Keyword"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["value"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,
                obj["Ad relevance"] === "Below average" ? parseFloat(obj[y_axis]) : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Ad group"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Ad relevance : <b>${obj["Ad relevance"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["Keyword"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["value"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                obj["Ad relevance"] === "Average" ? parseFloat(obj[y_axis]) : null,              
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Ad group"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Ad relevance : <b>${obj["Ad relevance"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["Keyword"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["value"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                obj["Ad relevance"] === "--" ? parseFloat(obj[y_axis]) : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Ad group"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Ad relevance : <b>${obj["Ad relevance"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["Keyword"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["value"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `]);
      }
      return final_res;
    }
    return [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]];
}

const ad_group_level_data = [
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "emergency contraceptive pills online",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Ad relevance": "--",
    "Exp. CTR": "--",
    "Cost": 283.62,
    "Impr.": 1363,
    "Clicks": 84,
    "Conversions": 38
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "ella after pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Ad relevance": "--",
    "Exp. CTR": "--",
    "Cost": 57.42,
    "Impr.": 73,
    "Clicks": 10,
    "Conversions": 5
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Keyword": "[emergency contraceptive pills online]",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Ad relevance": "--",
    "Exp. CTR": "--",
    "Cost": 0,
    "Impr.": 0,
    "Clicks": 0,
    "Conversions": 0
  },
  {
    "Campaign": "Brand - Cadence OTC",
    "Ad group": "Brand - Cadence OTC - Cadence",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "cadence pill",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Ad relevance": "Above average",
    "Exp. CTR": "Above average",
    "Cost": 2163.33,
    "Impr.": 1774,
    "Clicks": 458,
    "Conversions": 180
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "morning after pill 7 eleven",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Ad relevance": "Above average",
    "Exp. CTR": "Above average",
    "Cost": 529.2,
    "Impr.": 488,
    "Clicks": 159,
    "Conversions": 98
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "emergency contraception",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 1914.26,
    "Impr.": 9106,
    "Clicks": 583,
    "Conversions": 316
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "[morning after pill]",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 2130.63,
    "Impr.": 10315,
    "Clicks": 708,
    "Conversions": 333
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "morning after pill near me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 879.05,
    "Impr.": 2913,
    "Clicks": 267,
    "Conversions": 153
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "plan b near me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 1519.05,
    "Impr.": 6321,
    "Clicks": 388,
    "Conversions": 216
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "emergency contraception pill near me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 245,
    "Impr.": 404,
    "Clicks": 39,
    "Conversions": 15
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "morning after pill near me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 255.81,
    "Impr.": 418,
    "Clicks": 41,
    "Conversions": 20
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "[my choice pill]",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 171.51,
    "Impr.": 1026,
    "Clicks": 53,
    "Conversions": 26
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "my choice pill near me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 33.82,
    "Impr.": 143,
    "Clicks": 10,
    "Conversions": 6
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "pills to avoid pregnancy after 72 hours",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 309.67,
    "Impr.": 1780,
    "Clicks": 95,
    "Conversions": 40
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "emergency contraception pill near me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 1191.3,
    "Impr.": 4965,
    "Clicks": 355,
    "Conversions": 182
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "[cvs plan b]",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 194.74,
    "Impr.": 1769,
    "Clicks": 79,
    "Conversions": 34
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "plan b",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 4813.67,
    "Impr.": 30329,
    "Clicks": 1485,
    "Conversions": 736
  },
  {
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "costco plan b",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 206.88,
    "Impr.": 1517,
    "Clicks": 63,
    "Conversions": 32
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "my choice pill near me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Ad relevance": "Below average",
    "Exp. CTR": "Average",
    "Cost": 17.7,
    "Impr.": 28,
    "Clicks": 3,
    "Conversions": 2
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "emergency contraception",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 334.54,
    "Impr.": 714,
    "Clicks": 54,
    "Conversions": 24
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "pills to avoid pregnancy after 72 hours",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 165.41,
    "Impr.": 546,
    "Clicks": 33,
    "Conversions": 15
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "[morning after pill]",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Ad relevance": "Above average",
    "Exp. CTR": "Below average",
    "Cost": 527.62,
    "Impr.": 1056,
    "Clicks": 114,
    "Conversions": 42
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "[plan b walgreens]",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 56,
    "Impr.": 137,
    "Clicks": 11,
    "Conversions": 4
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "plan b near me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 273.17,
    "Impr.": 430,
    "Clicks": 41,
    "Conversions": 22
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "plan b",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 1219.14,
    "Impr.": 2570,
    "Clicks": 228,
    "Conversions": 89
  },
  {
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Keyword": "walgreens plan b",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Ad relevance": "Below average",
    "Exp. CTR": "Below average",
    "Cost": 31,
    "Impr.": 106,
    "Clicks": 6,
    "Conversions": 2
  }
];

const keyword_level_data = [
  {
    "Keyword": "pill to be taken after intercourse",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraceptive pills online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 159.16,
    "Impr.": 909,
    "Interactions": 47,
    "Conv. value": 210,
    "Conversions": 21
  },
  {
    "Keyword": "buy emergency contraception online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 27.44,
    "Impr.": 56,
    "Interactions": 7,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "emergency birth control online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[buy emergency contraception online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 31.16,
    "Impr.": 54,
    "Interactions": 9,
    "Conv. value": 58.33,
    "Conversions": 5.83
  },
  {
    "Keyword": "emergency birth control pills online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraceptive pills online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 36.04,
    "Impr.": 186,
    "Interactions": 11,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "[how to prevent pregnancy when condom breaks]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to prevent pregnancy after unprotected intercourse after a day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how can i prevent my pregnancy after 1 day]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "what is the way to prevent pregnancy within a day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to prevent pregnancy when condom breaks",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[what is the way to prevent pregnancy within a day]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how can i prevent my pregnancy after 1 day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "levonorgestrel near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill over 175 lbs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b morning after pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b morning after pill price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 17.72,
    "Impr.": 172,
    "Interactions": 7,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "the morning after pill locations",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[the morning after pill locations]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cost at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs brand plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cvs brand",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs limiting plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs brand plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where is plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cost at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs limiting plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cvs brand]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where is plan b in walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[can a man buy plan b pill at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "can a man buy plan b pill at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b in walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pregnancy pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[abortion pills plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b abortion pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b pregnancy pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 16.87,
    "Impr.": 136,
    "Interactions": 5,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "abortion pills plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "next choice one dose",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "take action pill walmart",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 29.82,
    "Impr.": 27,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "my choice pill pick up today",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella contraceptive near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill near me in store",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraceptive pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[before and after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pregnancy pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[heb emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where can i get emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency birth control pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraception coupon]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the emergency contraceptive pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pill price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency birth control]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pregnancy pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "before and after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is the emergency contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "pill to be taken after intercourse",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraceptive pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "heb emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraceptive pills online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control pills online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "buy emergency contraception online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[buy emergency contraception online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraceptive pills online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/products/morning-after-pill",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to prevent pregnancy when condom breaks",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how can i prevent my pregnancy after 1 day]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how can i prevent my pregnancy after 1 day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "what is the way to prevent pregnancy within a day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to prevent pregnancy after unprotected intercourse after a day",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[what is the way to prevent pregnancy within a day]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to have safe unprotected sex]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to prevent pregnancy when condom breaks]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to have safe unprotected sex",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[oral contraceptive pills over the counter]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 21.65,
    "Impr.": 30,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "oral contraceptive pills over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 6.88,
    "Impr.": 14,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "levonorgestrel near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "order morning after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[early morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "the morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "the morning after pill locations",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[next morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b morning after pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 6.61,
    "Impr.": 37,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "morning pill after",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "good morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how can i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the day after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning pill after]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b morning after pill price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest morning after",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "online morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "early morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how do i get the morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "next morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[the morning after pill locations]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "online morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill over 175 lbs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[order morning after pill online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[the morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the day after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get a morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "the morning after pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 33.76,
    "Impr.": 2,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "take action pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[take action pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[can a man buy plan b pill at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "next day pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b in cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs aftera",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[aftera cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cost walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cost at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[ella pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b pill cost cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b near me cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cost at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[next day pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 3.34,
    "Impr.": 4,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs aftera]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "do they sell plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much does plan b cost at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[do they sell plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs brand plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cvs pharmacy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b in cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to find plan b in cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill cost cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "take action pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where is plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs limiting plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[day after pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[ella morning after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs brand plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[do they sell plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where is plan b in walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cost walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to get plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs limiting plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[take action pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 3.53,
    "Impr.": 2,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b in walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "aftera cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b near me walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cvs pharmacy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where to find plan b in cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cvs brand]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "can a man buy plan b pill at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cvs brand",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b near me walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much does plan b cost at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella morning after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "do they sell plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b near me cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "is plan b sold over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to get a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[abortion pills plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b same day delivery",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i buy a plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "what stores have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pregnancy pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "new plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where do you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b pregnancy pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "do gas stations have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b contraceptive pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 14,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b for cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where can you get a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 29.84,
    "Impr.": 34,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "where do i buy plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[new plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b abortion pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "abortion pills plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b how much does it cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where can i find plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "affordable plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much for a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "20 dollar plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where is plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much for a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where do i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i find plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill costco",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill costco]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "--",
    "Quality Score": "--",
    "Exp. CTR": "--",
    "Ad relevance": "--",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cadence pill",
    "Match type": "Phrase match",
    "Campaign": "Brand - Cadence OTC",
    "Ad group": "Brand - Cadence OTC - Cadence",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Exp. CTR": "Above average",
    "Ad relevance": "Above average",
    "Cost": "1,543.22",
    "Impr.": 940,
    "Interactions": 221,
    "Conv. value": 976.33,
    "Conversions": 97.63
  },
  {
    "Keyword": "cadence otc",
    "Match type": "Phrase match",
    "Campaign": "Brand - Cadence OTC",
    "Ad group": "Brand - Cadence OTC - Cadence",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Exp. CTR": "Above average",
    "Ad relevance": "Above average",
    "Cost": 620.11,
    "Impr.": 834,
    "Interactions": 237,
    "Conv. value": 820,
    "Conversions": 82
  },
  {
    "Keyword": "711 plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 7.47,
    "Impr.": 6,
    "Interactions": 3,
    "Conv. value": 15,
    "Conversions": 1.5
  },
  {
    "Keyword": "plan b pill 7 11",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Exp. CTR": "Above average",
    "Ad relevance": "Above average",
    "Cost": 2.16,
    "Impr.": 3,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b 7 eleven]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 25.08,
    "Impr.": 18,
    "Interactions": 8,
    "Conv. value": 65,
    "Conversions": 6.5
  },
  {
    "Keyword": "7 11 plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill 7 eleven",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 10,
    "Exp. CTR": "Above average",
    "Ad relevance": "Above average",
    "Cost": 179.93,
    "Impr.": 175,
    "Interactions": 56,
    "Conv. value": 351.17,
    "Conversions": 35.12
  },
  {
    "Keyword": "[does 711 sell plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 6,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 6.36,
    "Impr.": 20,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[7 eleven plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 44.24,
    "Impr.": 53,
    "Interactions": 14,
    "Conv. value": 85,
    "Conversions": 8.5
  },
  {
    "Keyword": "plan b at 7 11",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 4.13,
    "Impr.": 4,
    "Interactions": 1,
    "Conv. value": 15,
    "Conversions": 1.5
  },
  {
    "Keyword": "[711 plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 34.87,
    "Impr.": 34,
    "Interactions": 9,
    "Conv. value": 70,
    "Conversions": 7
  },
  {
    "Keyword": "plan b 7 eleven",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b 7 11]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 52.12,
    "Impr.": 30,
    "Interactions": 16,
    "Conv. value": 70,
    "Conversions": 7
  },
  {
    "Keyword": "[7 11 plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 86.43,
    "Impr.": 64,
    "Interactions": 27,
    "Conv. value": 163.33,
    "Conversions": 16.33
  },
  {
    "Keyword": "7 eleven plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 8.23,
    "Impr.": 10,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[does 7 11 have plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 58.45,
    "Impr.": 55,
    "Interactions": 15,
    "Conv. value": 73.33,
    "Conversions": 7.33
  },
  {
    "Keyword": "does 7 11 have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b 7 11",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does 711 sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 6,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does 7 eleven have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - 7 Eleven",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Above average",
    "Quality Score": 6,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 19.71,
    "Impr.": 16,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "emergency contraception",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 900.23,
    "Impr.": 5102,
    "Interactions": 287,
    "Conv. value": 1405,
    "Conversions": 140.5
  },
  {
    "Keyword": "emergency contraception pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 2.9,
    "Impr.": 10,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 229.26,
    "Impr.": 729,
    "Interactions": 70,
    "Conv. value": 441.67,
    "Conversions": 44.17
  },
  {
    "Keyword": "[emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 289.91,
    "Impr.": 1016,
    "Interactions": 76,
    "Conv. value": 486.67,
    "Conversions": 48.67
  },
  {
    "Keyword": "[emergency contraception pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 197.97,
    "Impr.": 638,
    "Interactions": 53,
    "Conv. value": 330,
    "Conversions": 33
  },
  {
    "Keyword": "emergency contraception near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 56.53,
    "Impr.": 183,
    "Interactions": 14,
    "Conv. value": 125,
    "Conversions": 12.5
  },
  {
    "Keyword": "[how do i get the morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 15.09,
    "Impr.": 31,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "where to get morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 53.75,
    "Impr.": 116,
    "Interactions": 15,
    "Conv. value": 80,
    "Conversions": 8
  },
  {
    "Keyword": "next morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 764.15,
    "Impr.": 3911,
    "Interactions": 247,
    "Conv. value": 1192.5,
    "Conversions": 119.25
  },
  {
    "Keyword": "[the morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 3.86,
    "Impr.": 16,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 16.21,
    "Impr.": 41,
    "Interactions": 3,
    "Conv. value": 5,
    "Conversions": 0.5
  },
  {
    "Keyword": "cheapest morning after",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how can i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 6,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 842.34,
    "Impr.": 4295,
    "Interactions": 290,
    "Conv. value": 1345,
    "Conversions": 134.5
  },
  {
    "Keyword": "where can i get a morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 8.33,
    "Impr.": 15,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[emergency after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 72.16,
    "Impr.": 279,
    "Interactions": 22,
    "Conv. value": 120,
    "Conversions": 12
  },
  {
    "Keyword": "where can i get morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 5.19,
    "Impr.": 9,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "how to get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 2.81,
    "Impr.": 10,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[next morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 16.16,
    "Impr.": 50,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "how do you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 8.95,
    "Impr.": 6,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "the morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do i get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 7,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[the morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 76.02,
    "Impr.": 331,
    "Interactions": 30,
    "Conv. value": 175,
    "Conversions": 17.5
  },
  {
    "Keyword": "the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "otc morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "otc morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 49.57,
    "Impr.": 298,
    "Interactions": 15,
    "Conv. value": 80,
    "Conversions": 8
  },
  {
    "Keyword": "morning after pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "the morning after pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 7.3,
    "Impr.": 16,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "can you get the morning after pill over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 5.42,
    "Impr.": 7,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "morning after pill near me",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 812.13,
    "Impr.": 2586,
    "Interactions": 247,
    "Conv. value": 1434.17,
    "Conversions": 143.42
  },
  {
    "Keyword": "[plan b near me walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b near me walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[do they sell plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 13,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "do they sell plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 157.56,
    "Impr.": 777,
    "Interactions": 42,
    "Conv. value": 266.83,
    "Conversions": 26.68
  },
  {
    "Keyword": "plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 968.91,
    "Impr.": 4262,
    "Interactions": 243,
    "Conv. value": 1321.54,
    "Conversions": 132.15
  },
  {
    "Keyword": "do gas stations have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 10.88,
    "Impr.": 12,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "post day pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception pill near me",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 181.23,
    "Impr.": 343,
    "Interactions": 33,
    "Conv. value": 131.67,
    "Conversions": 13.17
  },
  {
    "Keyword": "how do you get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 8,
    "Exp. CTR": "Above average",
    "Ad relevance": "Above average",
    "Cost": 10.86,
    "Impr.": 1,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "most effective morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.65,
    "Impr.": 2,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "how to get the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "over the counter morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 16,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "otc morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill near me",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 210.17,
    "Impr.": 324,
    "Interactions": 37,
    "Conv. value": 160,
    "Conversions": 16
  },
  {
    "Keyword": "otc morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 11.88,
    "Impr.": 60,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "morning after pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 5,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "can you get the morning after pill over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 4,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does walgreens sell plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does cvs have plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "is plan b over the counter at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does cvs have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does walgreens sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is a plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[is plan b over the counter at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b nearby]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b nearby",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill pick up today",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 6.75,
    "Impr.": 10,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "ella pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 16.97,
    "Impr.": 194,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "my choice emergency contraceptive tablet",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 35.14,
    "Impr.": 158,
    "Interactions": 12,
    "Conv. value": 55,
    "Conversions": 5.5
  },
  {
    "Keyword": "ella after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 19.05,
    "Impr.": 131,
    "Interactions": 7,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "ella morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.29,
    "Impr.": 96,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "ella pill where to buy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 38.84,
    "Impr.": 155,
    "Interactions": 11,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "next choice one dose",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.19,
    "Impr.": 13,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[my choice pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 43.28,
    "Impr.": 248,
    "Interactions": 12,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "my choice pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "take action pill walmart",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill near me in store",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 13.61,
    "Impr.": 49,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "ella contraceptive near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 20.21,
    "Impr.": 84,
    "Interactions": 6,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "next day pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 5.6,
    "Impr.": 125,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[where can i get emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 35.19,
    "Impr.": 58,
    "Interactions": 9,
    "Conv. value": 75,
    "Conversions": 7.5
  },
  {
    "Keyword": "[emergency pill for pregnancy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 14.13,
    "Impr.": 108,
    "Interactions": 5,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[emergency birth control pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 24,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "before and after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraception coupon]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 13,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pregnancy pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 15,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraceptive pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill for pregnancy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is the emergency contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 3.76,
    "Impr.": 10,
    "Interactions": 2,
    "Conv. value": 15,
    "Conversions": 1.5
  },
  {
    "Keyword": "heb emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy emergency contraceptive pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 28.24,
    "Impr.": 171,
    "Interactions": 10,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "ecp pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 4.06,
    "Impr.": 11,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 34.58,
    "Impr.": 217,
    "Interactions": 9,
    "Conv. value": 55,
    "Conversions": 5.5
  },
  {
    "Keyword": "[emergency birth control]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 18.87,
    "Impr.": 108,
    "Interactions": 6,
    "Conv. value": 33.33,
    "Conversions": 3.33
  },
  {
    "Keyword": "emergency contraception coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "best emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 5.67,
    "Impr.": 23,
    "Interactions": 3,
    "Conv. value": 5,
    "Conversions": 0.5
  },
  {
    "Keyword": "how much is the emergency contraceptive pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 8.98,
    "Impr.": 86,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "emergency contraceptive pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 3.32,
    "Impr.": 45,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[heb emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 12.06,
    "Impr.": 27,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[before and after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency birth control",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pregnancy pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[best emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.75,
    "Impr.": 21,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pill price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 23.95,
    "Impr.": 126,
    "Interactions": 9,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "post day pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 2.91,
    "Impr.": 58,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "where to get emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.98,
    "Impr.": 19,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[emergency contraceptive pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 79.95,
    "Impr.": 334,
    "Interactions": 28,
    "Conv. value": 166.67,
    "Conversions": 16.67
  },
  {
    "Keyword": "emergency pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills Online",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 29.81,
    "Impr.": 158,
    "Interactions": 10,
    "Conv. value": 45,
    "Conversions": 4.5
  },
  {
    "Keyword": "what to do after unprotected sex to prevent pregnancy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to have safe unprotected sex",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "pills to avoid pregnancy after 72 hours",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 272.55,
    "Impr.": 1284,
    "Interactions": 82,
    "Conv. value": 356.67,
    "Conversions": 35.67
  },
  {
    "Keyword": "what to do after unprotected sex to prevent pregnancy",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 37.12,
    "Impr.": 483,
    "Interactions": 13,
    "Conv. value": 45,
    "Conversions": 4.5
  },
  {
    "Keyword": "[how to have safe unprotected sex]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "oral contraceptive pills over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 14.44,
    "Impr.": 56,
    "Interactions": 5,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[oral contraceptive pills over the counter]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 10.05,
    "Impr.": 94,
    "Interactions": 4,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "emergency contraception pill near me",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": "1,110.28",
    "Impr.": 4632,
    "Interactions": 332,
    "Conv. value": 1665.33,
    "Conversions": 166.53
  },
  {
    "Keyword": "after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 16.64,
    "Impr.": 219,
    "Interactions": 7,
    "Conv. value": 33.33,
    "Conversions": 3.33
  },
  {
    "Keyword": "[morning pill after]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 56.2,
    "Impr.": 174,
    "Interactions": 17,
    "Conv. value": 53.33,
    "Conversions": 5.33
  },
  {
    "Keyword": "morning pill after",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the day after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 2.3,
    "Impr.": 11,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[order morning after pill online]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 5.13,
    "Impr.": 5,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "morning after pill cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 4.47,
    "Impr.": 9,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[early morning pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "online morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 23,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "online morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "best morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 39,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill brand]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 5.68,
    "Impr.": 10,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "morning after pill coupon",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 3.24,
    "Impr.": 9,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cheapest morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 54.8,
    "Impr.": 106,
    "Interactions": 13,
    "Conv. value": 55,
    "Conversions": 5.5
  },
  {
    "Keyword": "morning after pill coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 4.85,
    "Impr.": 57,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "early morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the day after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill cost",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 37.31,
    "Impr.": 208,
    "Interactions": 13,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "morning after pill price",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 35.46,
    "Impr.": 135,
    "Interactions": 15,
    "Conv. value": 65,
    "Conversions": 6.5
  },
  {
    "Keyword": "order morning after pill online",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "good morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 1.81,
    "Impr.": 4,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "most effective morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill brand",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "over the counter morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Morning After Pill - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 4.64,
    "Impr.": 6,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 13.19,
    "Impr.": 32,
    "Interactions": 4,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "[morning after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 13.92,
    "Impr.": 108,
    "Interactions": 6,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "[plan b walgreens price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 1.9,
    "Impr.": 14,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[ella morning after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[aftera cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b near me cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 10,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs aftera",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much does plan b cost at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "next day pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b in cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill cost cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.95,
    "Impr.": 38,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[day after pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 7.12,
    "Impr.": 86,
    "Interactions": 4,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 31,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to get plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[take action pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 44,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b pill cost cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs aftera]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "is plan b over the counter at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b price cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[ella pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 4.86,
    "Impr.": 24,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[walgreens emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.8,
    "Impr.": 61,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[plan b price cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 1.27,
    "Impr.": 32,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 18.4,
    "Impr.": 104,
    "Interactions": 7,
    "Conv. value": 28.33,
    "Conversions": 2.83
  },
  {
    "Keyword": "[how much is a plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much does plan b cost at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "take action pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cvs pharmacy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "take action pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b near me cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 24.47,
    "Impr.": 89,
    "Interactions": 11,
    "Conv. value": 45,
    "Conversions": 4.5
  },
  {
    "Keyword": "[does walgreens have plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 22,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 22.73,
    "Impr.": 177,
    "Interactions": 10,
    "Conv. value": 35,
    "Conversions": 3.5
  },
  {
    "Keyword": "[does cvs have plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 28,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is the morning after pill at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 14,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is a plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much does plan b cost at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 6,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where to find plan b in cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b walgreens price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "aftera cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the morning after pill at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does walgreens have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cost walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[next day pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 5.09,
    "Impr.": 5,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[ella pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[do they sell plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[walgreens plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 56,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does cvs sell plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 16,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does walgreens sell plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 9,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does walgreens sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to find plan b in cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does cvs sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 7.24,
    "Impr.": 177,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[take action pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 28.73,
    "Impr.": 315,
    "Interactions": 9,
    "Conv. value": 65,
    "Conversions": 6.5
  },
  {
    "Keyword": "[plan b cvs pharmacy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 2.59,
    "Impr.": 19,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 10.19,
    "Impr.": 45,
    "Interactions": 5,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[day after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 20.29,
    "Impr.": 115,
    "Interactions": 10,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "[plan b in cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 9,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much does plan b cost at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[is plan b over the counter at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "do they sell plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella morning after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b cost walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does cvs have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do they sell plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 12.87,
    "Impr.": 10,
    "Interactions": 3,
    "Conv. value": 5,
    "Conversions": 0.5
  },
  {
    "Keyword": "[plan b nearby]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 41.88,
    "Impr.": 245,
    "Interactions": 11,
    "Conv. value": 75,
    "Conversions": 7.5
  },
  {
    "Keyword": "generic plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 29.96,
    "Impr.": 127,
    "Interactions": 7,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "where to buy plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 62.56,
    "Impr.": 186,
    "Interactions": 17,
    "Conv. value": 121.67,
    "Conversions": 12.17
  },
  {
    "Keyword": "plan b nearby",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 17.8,
    "Impr.": 73,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "plan b pills near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 8.35,
    "Impr.": 83,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cheap plan b near me]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 139.68,
    "Impr.": 305,
    "Interactions": 36,
    "Conv. value": 170,
    "Conversions": 17
  },
  {
    "Keyword": "plan b near me in store",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.38,
    "Impr.": 31,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 47.4,
    "Impr.": 111,
    "Interactions": 15,
    "Conv. value": 75,
    "Conversions": 7.5
  },
  {
    "Keyword": "is plan b sold over the counter",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 28.69,
    "Impr.": 111,
    "Interactions": 7,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "where to buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 116.43,
    "Impr.": 490,
    "Interactions": 31,
    "Conv. value": 190,
    "Conversions": 19
  },
  {
    "Keyword": "[cheap plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 31.02,
    "Impr.": 91,
    "Interactions": 8,
    "Conv. value": 15,
    "Conversions": 1.5
  },
  {
    "Keyword": "[where to find plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.24,
    "Impr.": 39,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "where can you get plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 49.94,
    "Impr.": 404,
    "Interactions": 19,
    "Conv. value": 75,
    "Conversions": 7.5
  },
  {
    "Keyword": "[how to get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 19.25,
    "Impr.": 76,
    "Interactions": 6,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "how much are plan b pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 24.93,
    "Impr.": 174,
    "Interactions": 10,
    "Conv. value": 41.67,
    "Conversions": 4.17
  },
  {
    "Keyword": "[where is plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b how much does it cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b planned parenthood",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 28.88,
    "Impr.": 242,
    "Interactions": 11,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "cheapest plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 87.23,
    "Impr.": 200,
    "Interactions": 21,
    "Conv. value": 120,
    "Conversions": 12
  },
  {
    "Keyword": "[how much are plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 4.48,
    "Impr.": 45,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where can you get a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 185.04,
    "Impr.": 587,
    "Interactions": 46,
    "Conv. value": 256.67,
    "Conversions": 25.67
  },
  {
    "Keyword": "places that sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.44,
    "Impr.": 9,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "where can you buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 17.99,
    "Impr.": 60,
    "Interactions": 5,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "[where can you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 66.98,
    "Impr.": 157,
    "Interactions": 18,
    "Conv. value": 100,
    "Conversions": 10
  },
  {
    "Keyword": "plan b alternatives",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.73,
    "Impr.": 144,
    "Interactions": 4,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[where can i find plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b same day delivery",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 19,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where do i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 16,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "what stores have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheap plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where is plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much plan b cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "generic plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 29.08,
    "Impr.": 205,
    "Interactions": 11,
    "Conv. value": 48.33,
    "Conversions": 4.83
  },
  {
    "Keyword": "where to buy a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 2.92,
    "Impr.": 32,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 2.21,
    "Impr.": 148,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 35,
    "Impr.": 170,
    "Interactions": 10,
    "Conv. value": 36.67,
    "Conversions": 3.67
  },
  {
    "Keyword": "where can you buy a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 7.32,
    "Impr.": 14,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 12,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 29,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much are plan b pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "affordable plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b contraceptive pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": "1,370.14",
    "Impr.": 12885,
    "Interactions": 418,
    "Conv. value": 2026.33,
    "Conversions": 202.63
  },
  {
    "Keyword": "[affordable plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 24.24,
    "Impr.": 40,
    "Interactions": 5,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "plan b cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 30.55,
    "Impr.": 314,
    "Interactions": 12,
    "Conv. value": 70,
    "Conversions": 7
  },
  {
    "Keyword": "plan b pill cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 7.78,
    "Impr.": 85,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[plan b planned parenthood]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 35.37,
    "Impr.": 251,
    "Interactions": 10,
    "Conv. value": 60,
    "Conversions": 6
  },
  {
    "Keyword": "[how much for a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 7,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i find plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill where to buy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to find plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 4.04,
    "Impr.": 11,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b for cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 34.66,
    "Impr.": 42,
    "Interactions": 9,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "how much are plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "affordable plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much plan b cost",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 3.03,
    "Impr.": 23,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do they sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 11.58,
    "Impr.": 43,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[plan b pill where to buy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 2.43,
    "Impr.": 26,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how do i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.36,
    "Impr.": 76,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "where can i buy a plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 3.24,
    "Impr.": 3,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do i buy plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 12.54,
    "Impr.": 10,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "planned parenthood plan b $9",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 55,
    "Impr.": 98,
    "Interactions": 18,
    "Conv. value": 98.33,
    "Conversions": 9.83
  },
  {
    "Keyword": "where to find plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 24.09,
    "Impr.": 146,
    "Interactions": 9,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "plan b price",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 685.8,
    "Impr.": 3413,
    "Interactions": 226,
    "Conv. value": 1156.33,
    "Conversions": 115.63
  },
  {
    "Keyword": "[how do you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to get a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 14,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is a plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 19.15,
    "Impr.": 87,
    "Interactions": 8,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[how much is plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 100.88,
    "Impr.": 640,
    "Interactions": 38,
    "Conv. value": 176.67,
    "Conversions": 17.67
  },
  {
    "Keyword": "[plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 818.79,
    "Impr.": 5463,
    "Interactions": 274,
    "Conv. value": 1273.33,
    "Conversions": 127.33
  },
  {
    "Keyword": "where can you get a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much for a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where can i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 25.92,
    "Impr.": 173,
    "Interactions": 7,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "best plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 1.8,
    "Impr.": 28,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 36.87,
    "Impr.": 136,
    "Interactions": 9,
    "Conv. value": 65,
    "Conversions": 6.5
  },
  {
    "Keyword": "[how much is a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 69.38,
    "Impr.": 640,
    "Interactions": 26,
    "Conv. value": 90,
    "Conversions": 9
  },
  {
    "Keyword": "[where to get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 102.33,
    "Impr.": 324,
    "Interactions": 25,
    "Conv. value": 150,
    "Conversions": 15
  },
  {
    "Keyword": "[cheap plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 449.22,
    "Impr.": 1031,
    "Interactions": 116,
    "Conv. value": 760.12,
    "Conversions": 76.01
  },
  {
    "Keyword": "[how much are plan b pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 9.15,
    "Impr.": 83,
    "Interactions": 4,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "where to buy cheap plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 13.61,
    "Impr.": 18,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[plan b coupon]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 80.37,
    "Impr.": 380,
    "Interactions": 26,
    "Conv. value": 94.5,
    "Conversions": 9.45
  },
  {
    "Keyword": "[plan b for overweight]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 6.82,
    "Impr.": 62,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where do you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 9.7,
    "Impr.": 70,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where to get plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 33,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b one step emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 109,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[new plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "20 dollar plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "new plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b for overweight",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 6.7,
    "Impr.": 48,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill costco]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 2.85,
    "Impr.": 62,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 25.05,
    "Impr.": 178,
    "Interactions": 9,
    "Conv. value": 45,
    "Conversions": 4.5
  },
  {
    "Keyword": "plan b walmart",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 2.81,
    "Impr.": 69,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "walmart plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 23.43,
    "Impr.": 156,
    "Interactions": 5,
    "Conv. value": 41.5,
    "Conversions": 4.15
  },
  {
    "Keyword": "costco plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 51.42,
    "Impr.": 328,
    "Interactions": 14,
    "Conv. value": 70,
    "Conversions": 7
  },
  {
    "Keyword": "walmart plan b generic",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 25.06,
    "Impr.": 295,
    "Interactions": 8,
    "Conv. value": 70,
    "Conversions": 7
  },
  {
    "Keyword": "cvs morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 14.42,
    "Impr.": 97,
    "Interactions": 6,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[plan b target]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 4.48,
    "Impr.": 29,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b costco",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 42.71,
    "Impr.": 244,
    "Interactions": 13,
    "Conv. value": 50,
    "Conversions": 5
  },
  {
    "Keyword": "morning after pill costco",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[walmart plan b generic]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 7.94,
    "Impr.": 11,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b target",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[my choice pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 9.5,
    "Impr.": 6,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "ella morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 10,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice emergency contraceptive tablet",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 10,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 9.69,
    "Impr.": 8,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "ella pill where to buy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.42,
    "Impr.": 12,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "my choice pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 9.39,
    "Impr.": 4,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "ella pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Competition - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 8.31,
    "Impr.": 24,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[emergency contraceptive pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 2.81,
    "Impr.": 18,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 23.94,
    "Impr.": 26,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 9.53,
    "Impr.": 14,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 1.89,
    "Impr.": 75,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "best emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[best emergency contraception]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency pill for pregnancy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 31.74,
    "Impr.": 7,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[emergency contraception pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 8.08,
    "Impr.": 31,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "emergency contraception",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 242.69,
    "Impr.": 495,
    "Interactions": 40,
    "Conv. value": 198.33,
    "Conversions": 19.83
  },
  {
    "Keyword": "[emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 12,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency pill for pregnancy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ecp pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "next day pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraceptive pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[emergency contraceptive pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Emergency Pills",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 13.86,
    "Impr.": 10,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "what to do after unprotected sex to prevent pregnancy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 2.67,
    "Impr.": 12,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "pills to avoid pregnancy after 72 hours",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 156.5,
    "Impr.": 436,
    "Interactions": 29,
    "Conv. value": 145,
    "Conversions": 14.5
  },
  {
    "Keyword": "what to do after unprotected sex to prevent pregnancy",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill - Unwanted Sex",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 6.24,
    "Impr.": 98,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "emergency contraception near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - EC Pill OTC - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 35.24,
    "Impr.": 17,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "morning after pill price",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 9,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get morning after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 23.63,
    "Impr.": 12,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 15.56,
    "Impr.": 12,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 23.22,
    "Impr.": 4,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "the morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 10.15,
    "Impr.": 5,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill brand]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "best morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cheapest morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill cost",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 8.86,
    "Impr.": 23,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[the morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 14.9,
    "Impr.": 25,
    "Interactions": 4,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "day after pill",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 197.5,
    "Impr.": 558,
    "Interactions": 42,
    "Conv. value": 150,
    "Conversions": 15
  },
  {
    "Keyword": "[morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 212.67,
    "Impr.": 345,
    "Interactions": 53,
    "Conv. value": 195,
    "Conversions": 19.5
  },
  {
    "Keyword": "where to buy morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill brand",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill coupon",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Morning After Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 1.69,
    "Impr.": 6,
    "Interactions": 1,
    "Conv. value": 5,
    "Conversions": 0.5
  },
  {
    "Keyword": "[how much does plan b cost at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 6,
    "Impr.": 1,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "how much does plan b cost at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b at walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does walgreens have plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b walgreens price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 12.72,
    "Impr.": 8,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[ella pill walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b walgreens price]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Above average",
    "Ad relevance": "Below average",
    "Cost": 3.57,
    "Impr.": 1,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is a plan b at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does cvs sell plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[day after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 4.69,
    "Impr.": 2,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[walgreens plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[morning after pill cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 10,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "does cvs sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "day after pill cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b price cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b price cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 20.46,
    "Impr.": 24,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[walgreens emergency contraceptive]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is the morning after pill at cvs]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 36,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is plan b at walgreens]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "ella pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[does walgreens have plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is the morning after pill at cvs",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "morning after pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens emergency contraceptive",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Pharmacy",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cheap plan b near me]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 6,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 10.84,
    "Impr.": 36,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "generic plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do they sell plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b near me in store",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 15.74,
    "Impr.": 13,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "plan b near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 212.38,
    "Impr.": 314,
    "Interactions": 32,
    "Conv. value": 160,
    "Conversions": 16
  },
  {
    "Keyword": "plan b pill near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 34.2,
    "Impr.": 39,
    "Interactions": 5,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "plan b pills near me",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Near me",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheapest plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 8.23,
    "Impr.": 8,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[where to get plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 6.9,
    "Impr.": 3,
    "Interactions": 2,
    "Conv. value": 6.67,
    "Conversions": 0.67
  },
  {
    "Keyword": "[where to find plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much is a plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how do i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b pill where to buy]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill price",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 13,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much plan b cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to find plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b coupon]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 14.81,
    "Impr.": 18,
    "Interactions": 3,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "where to buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 43.49,
    "Impr.": 32,
    "Interactions": 6,
    "Conv. value": 40,
    "Conversions": 4
  },
  {
    "Keyword": "[where to get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 36.34,
    "Impr.": 20,
    "Interactions": 6,
    "Conv. value": 35,
    "Conversions": 3.5
  },
  {
    "Keyword": "plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 415.51,
    "Impr.": 1268,
    "Interactions": 72,
    "Conv. value": 235,
    "Conversions": 23.5
  },
  {
    "Keyword": "plan b pill cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 4.16,
    "Impr.": 8,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[affordable plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "best plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 6,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much are plan b pills]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 8,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much are plan b pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b for overweight",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cheap plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cost",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 8.81,
    "Impr.": 40,
    "Interactions": 2,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where do you buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 6.32,
    "Impr.": 4,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "[how much is plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 33.1,
    "Impr.": 70,
    "Interactions": 7,
    "Conv. value": 35,
    "Conversions": 3.5
  },
  {
    "Keyword": "[plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 300.14,
    "Impr.": 460,
    "Interactions": 60,
    "Conv. value": 203.33,
    "Conversions": 20.33
  },
  {
    "Keyword": "[how much is a plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 16.67,
    "Impr.": 67,
    "Interactions": 4,
    "Conv. value": 30,
    "Conversions": 3
  },
  {
    "Keyword": "where can you buy a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much are plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cheap plan b pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 8.33,
    "Impr.": 5,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 17.98,
    "Impr.": 1,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "how much plan b cost",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to buy cheap plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to find plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 11.98,
    "Impr.": 27,
    "Interactions": 2,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[plan b for overweight]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 9.89,
    "Impr.": 9,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b pills",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0.48,
    "Impr.": 16,
    "Interactions": 1,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how much are plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much are plan b pills",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 4,
    "Exp. CTR": "Average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b planned parenthood]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b cheap",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 6,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how do you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get plan b",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b coupon",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "places that sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can i buy plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 30.77,
    "Impr.": 17,
    "Interactions": 5,
    "Conv. value": 25,
    "Conversions": 2.5
  },
  {
    "Keyword": "[where can i get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 28.01,
    "Impr.": 25,
    "Interactions": 5,
    "Conv. value": 20,
    "Conversions": 2
  },
  {
    "Keyword": "[where can you get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 5,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[how to get plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 11,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how to get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 5,
    "Exp. CTR": "Average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how do you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "planned parenthood plan b $9",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 19.37,
    "Impr.": 3,
    "Interactions": 3,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "where to buy a plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 5.22,
    "Impr.": 2,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b price",
    "Match type": "Broad match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 107.86,
    "Impr.": 265,
    "Interactions": 28,
    "Conv. value": 90,
    "Conversions": 9
  },
  {
    "Keyword": "[cheap plan b]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 48.15,
    "Impr.": 37,
    "Interactions": 8,
    "Conv. value": 35,
    "Conversions": 3.5
  },
  {
    "Keyword": "generic plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 6.78,
    "Impr.": 12,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "where do they sell plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 1,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b alternatives",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b pill where to buy",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Below average",
    "Ad relevance": "Above average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b planned parenthood",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where to get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "affordable plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 3,
    "Exp. CTR": "Average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b one step pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "where can you get plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "how much is a plan b pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Plan B - Plan B Pill",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 2,
    "Exp. CTR": "Below average",
    "Ad relevance": "Average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walgreens plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 14.7,
    "Impr.": 24,
    "Interactions": 2,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b costco",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b target",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walmart plan b generic",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 0,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "plan b walmart",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 5,
    "Impr.": 13,
    "Interactions": 1,
    "Conv. value": 10,
    "Conversions": 1
  },
  {
    "Keyword": "plan b pill walgreens",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[cvs morning after pill]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 11.3,
    "Impr.": 18,
    "Interactions": 3,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "walmart plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 20,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "costco plan b",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 15,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[plan b target]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 3,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "[walmart plan b generic]",
    "Match type": "Exact match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 2,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  },
  {
    "Keyword": "cvs morning after pill",
    "Match type": "Phrase match",
    "Campaign": "NonBrand - Store Locations - Circle K",
    "Ad group": "NonBrand - Store Locations - Stores - Others",
    "Final URL": "https://cadenceotc.com/pages/morning-after-pill-stores-near-me",
    "Landing page exp.": "Below average",
    "Quality Score": 1,
    "Exp. CTR": "Below average",
    "Ad relevance": "Below average",
    "Cost": 0,
    "Impr.": 4,
    "Interactions": 0,
    "Conv. value": 0,
    "Conversions": 0
  }
]