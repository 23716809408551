import React from "react";
import alasql from "alasql";

export function SankeyNodeComponent(props){
    if(props.payload.value < 1){
        return ""
    }
    
    return (
        <g style={{width:"20px", wordWrap:"break-word"}}>
            <rect
                x={props.x}
                y={props.y}
                width={props.payload.dx}
                height={props.payload.dy}
                fill={props["journey_by_color_mapping_dict"][props.payload.name][0]}
                // stroke="#000"
            />
            <text
                x={props.x}
                y={props.y - 5}
                style={{fontSize:"12px"}}
                textAnchor="middle"
                fill="#000"
                className="sankey-word-warp"
            >
            {props.payload.name === "" ? "N/A" : (props.payload.name.length > 30 ? props.payload.name.substring(0,30) + "..." : props.payload.name)}
            </text>
        </g>
    );
}


export function SankeyLinkComponent(props){
  
    return (
        <g>
            <path
                className="recharts-sankey-link" 
                d={`M${props.sourceX},${props.sourceY}C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}`}
                stroke={props["journey_by_color_mapping_dict"][props.payload.source.name][1]}
                strokeWidth={props.linkWidth}
                fill="none"
                strokeOpacity={1}
            />
        </g>
    );
}


export function SankeyCustomTooltip(props){
    console.log(props)
    if(props["active"] && props?.payload !== undefined && props["payload"].length){
        if(props["payload"][0]?.payload !== undefined){
            if(props["payload"][0]["payload"]?.payload !== undefined){
                if((!Object.keys(props["payload"][0]["payload"]["payload"]).includes("source")) || (!Object.keys(props["payload"][0]["payload"]["payload"]).includes("target"))){

                    let current_node_name = props["payload"][0]["payload"]["payload"]["name"];
                    let sourceNodes = props["payload"][0]["payload"]["payload"]["sourceNodes"];
                    let targetNodes = props["payload"][0]["payload"]["payload"]["targetNodes"];
                    let node_link_mapping = props["node_link_mapping"];
                    let order_data = props["order_data"];

                    let source_product_val_arr = [];
                    let source_order_no = null;
                    for(let source_iter of sourceNodes){
                        for(let i=0 ; i<node_link_mapping.length ; i++){
                            for(let [key, value] of Object.entries(node_link_mapping[i])){
                                if(value === source_iter){
                                    source_order_no = i;
                                    source_product_val_arr.push(key);
                                }
                            }
                        }
                    }

                    let target_product_val_arr = [];
                    let target_order_no = null;
                    for(let target_iter of targetNodes){
                        for(let i=0 ; i<node_link_mapping.length ; i++){
                            for(let [key, value] of Object.entries(node_link_mapping[i])){
                                if(value === target_iter){
                                    target_order_no = i;
                                    target_product_val_arr.push(key);
                                }
                            }
                        }
                    }
                
                    let res = alasql(`SELECT customer_id, created_at, STRING_SPLIT(${props["journey_by_value"]}, "<br />") as ${props["journey_by_value"]} FROM ? ORDER BY customer_id, created_at`,[order_data]);
                    res = alasql(`SELECT customer_id, ARRAY(${props["journey_by_value"]}) as from_to_array, ARRAY(created_at) as created_at FROM ? GROUP BY customer_id`,[res]);

                    let selected_total_order = 0;
                    let selected_node_order = 0;
                    let selected_source_order = 0;
                    let selected_source_date = 0;
                    let selected_target_order = 0;
                    let selected_target_date = 0;
                    for(let res_iter of res){
                        if((source_order_no !== null) && ((source_order_no+1) < res_iter["from_to_array"].length)){
                            selected_total_order += res_iter["from_to_array"][source_order_no+1].length;
                            if(res_iter["from_to_array"][source_order_no+1].includes(current_node_name)){
                                selected_node_order++;                                
                            }
                        }
                        else if((target_order_no !== null) && ((target_order_no-1) < res_iter["from_to_array"].length)){
                            selected_total_order += res_iter["from_to_array"][target_order_no-1].length;
                            if(res_iter["from_to_array"][target_order_no-1].includes(current_node_name)){
                                selected_node_order++;                                
                            }
                        }

                        if((source_order_no !== null) && ((source_order_no+1) < res_iter["from_to_array"].length)){
                            if(res_iter["from_to_array"][source_order_no].some(r=> source_product_val_arr.includes(r)) && res_iter["from_to_array"][source_order_no+1].includes(current_node_name)){
                                selected_source_date += (new Date(res_iter["created_at"][source_order_no+1]) - new Date(res_iter["created_at"][source_order_no]));
                                selected_source_order++;
                            }
                        }
                        if((target_order_no !== null) && (target_order_no < res_iter["from_to_array"].length)){
                            if(res_iter["from_to_array"][target_order_no].some(r=> target_product_val_arr.includes(r)) && res_iter["from_to_array"][target_order_no].includes(current_node_name)){
                                selected_target_date += (new Date(res_iter["created_at"][target_order_no]) - new Date(res_iter["created_at"][target_order_no-1]));
                                selected_target_order++;
                            }
                        }
                    }
                    
                    // console.log("----------------------------------");
                    // console.log(res)
                    // console.log(selected_node_order);
                    // console.log(selected_total_order);
                    // console.log("----------------------------------");
                    let final_source_avg_days = Math.floor((selected_source_date/selected_source_order)/(24*60*60*1000));
                    let final_target_avg_days = Math.floor((selected_target_date/selected_target_order)/(24*60*60*1000));
                    let final_current_order = source_order_no ? source_order_no+2 : target_order_no ? target_order_no: "";
                    let final_selected_order_percentage = ((selected_node_order/selected_total_order)*100).toFixed(1);

                    return (
                        <>
                            <div style={{backgroundColor:"white", padding:"20px", width:"fit-content", borderRadius:"5px" , boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
                                <h6>{current_node_name === "" ? "N/A" : current_node_name}</h6>
                                <p>{final_selected_order_percentage? final_selected_order_percentage : ""}% purchases as {final_current_order}{final_current_order===1 ? "st" : final_current_order===2 ? "nd" : final_current_order===3 ? "rd" : final_current_order>=4 ? "th": ""} order</p>
                                <hr/>
                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                    <div style={{width:"200px"}}>
                                        <h6>Avg. time from prev order</h6>
                                        <p>{final_source_avg_days? final_source_avg_days: 0} days</p>
                                    </div>
                                    <div style={{width:"200px"}}>
                                        <h6>Avg. time for next order</h6>
                                        <p>{final_target_avg_days? final_target_avg_days: 0} days</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        }
    }

    return ""
}