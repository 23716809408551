import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function ProductAudit({ViewedPage, shop_domain, app_url}) {
    ViewedPage(12);
    
    const [loading, setLoading] = useState(false);

    const handleGetAudit = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setTimeout(() => {
                document.getElementById("sheet_link_div").style.display = "block";
            }, 200)
        }, 500)
    }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>All Product Audit</h2>
                            </div>

                            <Container className='mt-3 mb-2'>
                                <Row className='justify-content-md-left' lg={2} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Select Product</Label>
                                        <br />
                                        <FormSelect id="change_audit_type" defaultValue="all_website">
                                            <option key="all_website" value="all_website">All Website</option>
                                            <option key="product" value="product">Product</option>
                                            <option key="product_type" value="product_type">Product Type</option>
                                            <option key="sku" value="sku">SKU</option>
                                        </FormSelect>    
                                    </Col>                                    
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handleGetAudit}>
                                        Get Audit
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>

                            <div id='sheet_link_div' className='mt-3 mx-2 p-2' style={{display:"none" ,backgroundColor: "white", textAlign:"center"}}>
                                <Link to="https://docs.google.com/spreadsheets/d/1qbJnU6SLv4JKyCQRB6Inf--5TCdaxaAcIPFnldhmQIY/edit?gid=0#gid=0" target="_blank">Divine Collections - All Product Audit</Link>
                            </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default ProductAudit