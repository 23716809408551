import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { DateRangePicker } from "rsuite";
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import FormSelect from 'react-bootstrap/FormSelect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


alasql.fn.GET_UNIQUE_VALUE_OF_ARRAY = function (arr){
    if((arr === undefined) || (arr === null) || (arr.length === 0)){
        return [];
    }
    return Array.from(new Set(arr.flat()));
}
alasql.fn.CHECK_VALUE_EXIST_IN_ARRAY = function (arr, operator, compare_arr_str){
    let compare_arr = compare_arr_str.split("<br />");
    if(operator === "IN"){
        for(let iter of arr){
            if(compare_arr.includes(iter)){
                return true;
                }
            }
        return false;
    }
    else{
        for(let iter of arr){
            if(! compare_arr.includes(iter)){
                return true;
                }
            }
        return false;
    }
}


function ProductPerformance({ViewedPage, shop_domain, app_url}) {
    ViewedPage(6);

    const [loading, setLoading] = useState(true);
    const [selected_metrics, setSelectedMetrics] = useState("Revenue");
    const [show_filters, setShowfilters] = useState(false);
    const [filter_unique_value_dict, setFilterUniqueValueDict] = useState({
        "product_title":[],
        "product_type":[],
        "sku":[],
    });
    const [selected_filter_unique_value_dict, setSelectedFilterUniqueValueDict] = useState({
        "product_title":{"operator":"IN","filter_val":[],"order_no":"any"},
        "product_type":{"operator":"IN","filter_val":[],"order_no":"any"},
        "sku":{"operator":"IN","filter_val":[],"order_no":"any"},
    });
    const [selected_filter_total_value, setSelectedFilterTotalValue] = useState(0);
    const [currency_code, setCurrencyCode] = useState("");
    const [show_charts_on, setShowChartsOn] = useState("utm_campaign");
    const [selected_date_range_value, setDateRange] = useState([]);
    const [order_raw_data, setOrderRawData] = useState([]);
    const [pie_chart_data, setPieChartData] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    console.log(data)
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setSelectedFilterTotalValue(get_total_value_of_selected_metrics(formated_data, "total_price", {"product_title":{"operator":"IN","filter_val":[],"order_no":"any"},"product_type":{"operator":"IN","filter_val":[],"order_no":"any"},"sku":{"operator":"IN","filter_val":[],"order_no":"any"}}));
                    setCurrencyCode(get_currency_code(formated_data));
                    setFilterUniqueValueDict(get_filter_unique_value(data.data.order_data));
                    setOrderRawData(formated_data);
                    setPieChartData(get_filter_data(formated_data, "utm_campaign", "total_price", {"product_title":{"operator":"IN","filter_val":[],"order_no":"any"},"product_type":{"operator":"IN","filter_val":[],"order_no":"any"},"sku":{"operator":"IN","filter_val":[],"order_no":"any"}}));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });                
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        setDateRange(date_range);
        var created_at_max = new Date();
        var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
        if(date_range.length > 0){
            created_at_min = date_range[0];
            created_at_max = date_range[1];
        }

        var metrics = document.getElementById("metrics").value;
        
        setLoading(true);

        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    setSelectedFilterTotalValue(get_total_value_of_selected_metrics(formated_data, metrics, selected_filter_unique_value_dict));
                    setCurrencyCode(get_currency_code(formated_data));
                    setFilterUniqueValueDict(get_filter_unique_value(data.data.order_data));
                    setOrderRawData(formated_data);
                    setPieChartData(get_filter_data(formated_data, show_charts_on, metrics, selected_filter_unique_value_dict));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }

    const handleFilters = async (e) => {
        e.preventDefault();
        var utm_parameter = document.getElementById("utm_parameter").value;
        var metrics = document.getElementById("metrics").value;
        setLoading(true);
        try{
            setSelectedMetrics(metrics === "total_price" ? "Revenue" : (metrics === "current_total_price" ? "Net Revenue" : "Profit"));
            setShowChartsOn(utm_parameter);
            setSelectedFilterTotalValue(get_total_value_of_selected_metrics(order_raw_data, metrics, selected_filter_unique_value_dict));
            setCurrencyCode(get_currency_code(order_raw_data));
            setPieChartData(get_filter_data(order_raw_data, utm_parameter, metrics, selected_filter_unique_value_dict));
            setLoading(false);
        }   
        catch(err){
            setLoading(false);
            toast.error("No Data to Show.");
            console.log(err)
        }
    }


    const handle_filters_hide = () => setShowfilters(false);
    const handle_filters_show = () => setShowfilters(true);
    const handle_filters_submit = (e) => {
        e.preventDefault();
        handle_filters_hide();
        handleFilters(e);
    };


    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    <Modal show={show_filters} onHide={handle_filters_hide}>
                        <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{height:"60vh", overflowY:"scroll"}}>
                            
                            {
                                ["product_title","product_type","sku"].map((val1) => {
                                    return (                            
                                        <Container>
                                            <Row className='my-3'>
                                                <p className='charts_popup_filter_heading'>{val1 === "product_title"? "Product Title": (val1 === "product_type"? "Product Type": "SKU")}</p>
                                            </Row>
                                            <Row>
                                                <ReMultiSelect 
                                                    className='popup_filter_include_exclude'
                                                    closeMenuOnSelect={false}
                                                    id={`${val1}_include_exclude`} 
                                                    components={ReMultiSelectAnimated()}
                                                    defaultValue={[{ value: selected_filter_unique_value_dict[`${val1}`]["operator"] === "NOT IN"? "exclude":"include", label: selected_filter_unique_value_dict[`${val1}`]["operator"] === "NOT IN"? "Exclude":"Include" }]}
                                                    options={[
                                                        { value: 'include', label: 'Include' },
                                                        { value: 'exclude', label: 'Exclude' },
                                                    ]}
                                                    onChange={(selected_vals) => {
                                                        let temp_filter_dict = { ...selected_filter_unique_value_dict };
                                                        temp_filter_dict[`${val1}`]["operator"] = selected_vals["value"] === "exclude"? "NOT IN": "IN";
                                                        setSelectedFilterUniqueValueDict(temp_filter_dict);
                                                    }}
                                                />
                                            </Row>
                                            <Row>
                                                <ReMultiSelect
                                                    className='popup_filter_options'
                                                    id={`${val1}_options`}
                                                    closeMenuOnSelect={false}
                                                    components={ReMultiSelectAnimated()}
                                                    defaultValue={selected_filter_unique_value_dict[`${val1}`]["filter_val"].map((x) => {
                                                        return {"value":x,"label":x}
                                                    })}
                                                    isMulti
                                                    options={filter_unique_value_dict[`${val1}`]}
                                                    onChange={(selected_vals) => {
                                                        let temp_filter_dict = { ...selected_filter_unique_value_dict };
                                                        temp_filter_dict[`${val1}`]["filter_val"] = selected_vals.map((x) => { return x["value"]});
                                                        setSelectedFilterUniqueValueDict(temp_filter_dict);
                                                    }}
                                                />
                                            </Row>
                                        </Container>
                                    )
                                })
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handle_filters_hide}>Close</Button>
                            <Button variant="primary" onClick={handle_filters_submit}>Submit</Button>
                        </Modal.Footer>
                    </Modal>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Performance by Channel</h2>
                            </div>

                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={4} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Metrics</Label>
                                        <br />
                                        <FormSelect defaultValue={"total_price"} id='metrics' onChange={handleFilters}>
                                            <option key="total_price" value="total_price">Revenue</option>
                                            <option key="current_total_price" value="current_total_price">Net Revenue</option>
                                            <option key="profit" value="profit">Profit</option>
                                        </FormSelect>                                        
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">UTM Parameter</Label>
                                        <br />
                                        <FormSelect defaultValue={"utm_campaign"} id='utm_parameter' onChange={handleFilters}>
                                            <option key="utm_campaign" value="utm_campaign">UTM Campaign</option>
                                            <option key="utm_content" value="utm_content">UTM Content</option>
                                            <option key="utm_medium" value="utm_medium">UTM Medium</option>
                                            <option key="utm_term" value="utm_term">UTM Term</option>
                                            <option key="utm_source" value="utm_source">UTM Source</option>
                                        </FormSelect>                                        
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handle_filters_show}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
                                            <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
                                        </svg>
                                        Filters
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                                
                            <Container className='mt-3'>
                                <Row className='justify-content-md-left' lg={1} xs={1}>
                                    <Col className='m-2 p-2' style={{backgroundColor:"white", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                        <div style={{textAlign:"center"}}>
                                            <h3>Total {selected_metrics}</h3>
                                            <h5>{selected_filter_total_value.toLocaleString()} {currency_code}</h5>
                                        </div>
                                        <div>
                                            <Chart
                                                chartType="PieChart"
                                                width="800px"
                                                height="400px"
                                                data={pie_chart_data}
                                                options={{
                                                    is3D: false,
                                                    pieHole: 0.5,
                                                    colors: ["#40c4ff","#0288d1","#9fa8da","#5c6bc0","#7e57c2"]
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default ProductPerformance


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function jsonToArrayObject(res, graph_columns) {
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        res_arr.push(obj[column_name]);
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}

function apply_filter_on_data(data, selected_filter){
    let filtered_data = data;
    for(let key of Object.keys(selected_filter)){
        if(selected_filter[key]["filter_val"].length > 0){
            filtered_data = alasql(`SELECT * FROM ? WHERE CHECK_VALUE_EXIST_IN_ARRAY(STRING_SPLIT(${key}, "<br />"),"${selected_filter[key]["operator"]}","${selected_filter[key]["filter_val"]}")`,[filtered_data]);
        }
    }
    return filtered_data;
}


function get_filter_unique_value(order_data){    
    var temp_filter_data = {};
    temp_filter_data["product_title"] = alasql(`SELECT ConvertArrayToFilterLabel(GET_UNIQUE_VALUE_OF_ARRAY(ARRAY(STRING_SPLIT(product_title, "<br />")))) as product_title FROM ?`,[order_data])[0]["product_title"];
    temp_filter_data["product_type"] = alasql(`SELECT ConvertArrayToFilterLabel(GET_UNIQUE_VALUE_OF_ARRAY(ARRAY(STRING_SPLIT(product_type, "<br />")))) as product_type FROM ?`,[order_data])[0]["product_type"];
    temp_filter_data["sku"] = alasql(`SELECT ConvertArrayToFilterLabel(GET_UNIQUE_VALUE_OF_ARRAY(ARRAY(STRING_SPLIT(sku, "<br />")))) as sku FROM ?`,[order_data])[0]["sku"];

    return temp_filter_data;
}


function formate_shopify_data(data){
    var res = alasql('SELECT country, created_at, getDateString(created_at) as Date, currency_code, CAST(current_total_price AS FLOAT) as current_total_price, customer_id, customer_joining_date, customer_tags, discount_code, discount_codes, id, last_visit_campaign as utm_campaign, last_visit_content as utm_content, last_visit_medium as utm_medium, last_visit_source as utm_source, last_visit_term as utm_term, product_title, product_type, profit, sku, tags, CAST(total_price AS FLOAT) as total_price FROM ?',[data]);
    
    return res;
}

function get_total_value_of_selected_metrics(data, metrics, selected_filter){
    let filter_data = apply_filter_on_data(data, selected_filter);
    var res = alasql(`SELECT SUM(${metrics}) as ${metrics} FROM ? `,[filter_data]);
    return res[0][metrics];
}

function get_currency_code(data){
    var res = alasql(`SELECT FIRST(currency_code) as currency_code FROM ? `,[data]);
    return res[0]["currency_code"];
}

function get_filter_data(order_data, filter_on, metrics,  selected_filter){
    let filter_data = apply_filter_on_data(order_data, selected_filter);
    let res = alasql(`SELECT ${filter_on}, SUM(${metrics}) as ${metrics} FROM ? GROUP BY ${filter_on}`,[filter_data]);
    console.log(res)
    return jsonToArrayObject(res,[filter_on,metrics]);
}