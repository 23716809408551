import React, {useState,useEffect} from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { DateRangePicker } from "rsuite";
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import alasql from 'alasql';
import errorMessages from '../errorMessages.js';

alasql.fn.ReplaceNullValues = function(val){
  return ((val === Infinity) || (val === undefined)) ? 0 : val;
}

alasql.fn.Add_Image_HTML_tag = function(val){
  return `<img src="${val}" alt="Image" width=50 height=50> </img>`;
}

const all_scattered_charts_type = {
  "Keyword": [
    { value: 'clicks', label: 'Clicks' },
    { value: 'conversions', label: 'Conv.' },
    { value: 'conversions_value', label: 'Conv. value' },
    { value: 'all_conversions', label: 'All Conv.' },
    { value: 'all_conversions_value', label: 'All Conv. value' },
    { value: 'cost', label: 'Cost' },
    { value: 'impressions', label: 'Impr.' },
  ],
  "Ad group": [
    { value: 'clicks', label: 'Clicks' },
    { value: 'conversions', label: 'Conv.' },
    { value: 'all_conversions', label: 'All Conv.' },
    { value: 'conversions_value', label: 'Conv. value' },
    { value: 'all_conversions_value', label: 'All Conv. value' },
    { value: 'cost', label: 'Cost' },
    { value: 'impressions', label: 'Impr.' },
  ],
}


function LandingPageOptimization({ViewedPage, shop_domain, app_url}) {
    ViewedPage(19);

    const { combine, before, afterToday } = DateRangePicker;
    const max_date = new Date();
    max_date.setDate(max_date.getDate() - 365);

    const [loading, setLoading] = useState(true);
    const [analyze_btn_loading, setAnalyzeBtnLoading] = useState(false);
    const [find_more_variation_loading, setFindMoreVariationsLoading] = useState(false);
    const [generate_btn_loading, setGenerateBtnLoading] = useState(false);
    const [show_landing_page, setShowLandingPageURL] = useState(false);
    const [show_final_landing_page, setShowFinalLandingPageURL] = useState(false);
    const [apply_customization_btn_loading, setApplyCustomizeBtnLoading] = useState(false);
    const [spreadsheet_url, setSheetUrl] = useState("");
    const [title_x_axis, setTitleXAxis] = useState("Cost");
    const [title_y_axis, setTitleYAxis] = useState("Clicks");
    const [x_axis, setXAxis] = useState({ value: 'cost', label: 'Cost' });
    const [y_axis, setYAxis] = useState({ value: 'clicks', label: 'Clicks' });
    const [price_range, setPriceRange] = useState([["All","All"]]);
    const [landing_page_exp, setAdStrengthValue] = useState("All");
    const [scattered_plot_type, setScatteredPlotType] = useState("Keyword");
    const [all_keywords, setAllKeywords]  = useState([]);
    const [all_landing_page_urls, setAllLandingPageURLS]  = useState([]);
    const [landing_page_settings, setLandingPageSettings]  = useState({});
    const [selected_keywords, setSelectedKeywords] = useState("");
    const [selected_landing_page_url, setSelectedLandingPageUrl]  = useState("");
    const [scatter_overview_data, setScatterOverviewData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selected_suggestion, setSelectedSuggestion] = useState([]);
    const [table_data_level, setTableDataLevel] = useState("Ad group");
    const [custom_input, setCustomInput] = useState([]);
    const [filter_date, setFilterDate] = useState([]);
    const [keyword_level_data, setKeywordLevelData] = useState([]);
    const [ad_group_level_data, setAdGroupLevelData] = useState([]);
    
    const [current_metric_options, setCurrentMetricOptions] = useState(all_scattered_charts_type[scattered_plot_type]);

    const [keyword_performance_data, setKeywordPerformanceData] = useState([]);
    const [selected_line_chart_keyword_1, setSelectedLineChartsKeywords1] = useState("");
    const [selected_line_chart_metrics_1, setSelectedLineChartsMetrics1] = useState({});
    const [selected_line_chart_keyword_2, setSelectedLineChartsKeywords2] = useState("");
    const [selected_line_chart_metrics_2, setSelectedLineChartsMetrics2] = useState({});
    const [keyword_performance_line_chart_type_1, setKeywordPerformanceLineChartType1] = useState("Keyword");
    const [keyword_performance_line_chart_type_2, setKeywordPerformanceLineChartType2] = useState("Keyword");
    const [keyword_performance_line_chart_all_keywords_1, setKeywordPerformanceLineChartAllKeywords1] = useState([]);
    const [keyword_performance_line_chart_all_keywords_2, setKeywordPerformanceLineChartAllKeywords2] = useState([]);
    const keyword_performance_line_chart_metrics_1 = [
      { value: "SUM(CAST(clicks AS FLOAT))", label: "Clicks" },
      { value: "SUM(CAST(conversions AS FLOAT))", label: "Conv." },
      { value: "SUM(CAST(all_conversions AS FLOAT))", label: "All Conv." },
      { value: "SUM(CAST(conversions_value AS FLOAT))", label: "Conv. value" },
      { value: "SUM(CAST(all_conversions_value AS FLOAT))", label: "All Conv. value" },
      { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" },
      { value: "SUM(CAST(impressions AS FLOAT))", label: "Impr." },
      { value:"SUM(CAST(conversions_value AS FLOAT))/SUM(CAST(cost AS FLOAT))", label:"ROAS"},
      { value:"SUM(CAST(cost AS FLOAT))/SUM(CAST(conversions AS FLOAT))", label:"CPA"},
      { value:"SUM(CAST(conversions AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CVR"},
      { value:"SUM(CAST(cost AS FLOAT))/SUM(CAST(clicks AS FLOAT))", label:"CPC"},
      { value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"},
    ];
    const keyword_performance_line_chart_metrics_2 = [
      { value: "SUM(CAST(clicks AS FLOAT))", label: "Clicks" },
      { value: "SUM(CAST(conversions AS FLOAT))", label: "Conv." },
      { value: "SUM(CAST(all_conversions AS FLOAT))", label: "All Conv." },
      { value: "SUM(CAST(conversions_value AS FLOAT))", label: "Conv. value" },
      { value: "SUM(CAST(all_conversions_value AS FLOAT))", label: "All Conv. value" },
      { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" },
      { value: "SUM(CAST(impressions AS FLOAT))", label: "Impr." },
      { value:"SUM(CAST(conversions_value AS FLOAT))/SUM(CAST(cost AS FLOAT))", label:"ROAS"},
      { value:"SUM(CAST(cost AS FLOAT))/SUM(CAST(conversions AS FLOAT))", label:"CPA"},
      { value:"SUM(CAST(conversions AS FLOAT))/SUM(CAST(clicks AS FLOAT))*100", label:"CVR"},
      { value:"SUM(CAST(cost AS FLOAT))/SUM(CAST(clicks AS FLOAT))", label:"CPC"},
      { value:"SUM(CAST(clicks AS FLOAT))/SUM(CAST(impressions AS FLOAT))*100", label:"CTR"},
    ];
    const [keyword_performance_line_chart_data_1, setKeywordPerformanceLineChartData1] = useState([]);
    const [keyword_performance_line_chart_data_2, setKeywordPerformanceLineChartData2] = useState([]);

    const line_charts_config = {
      chartArea: {'width': '80%', 'height': '70%', 'top':'10%'},
      titleTextStyle : {fontSize: 16},
      hAxis: { 
          format: 'MMM d, y',
          gridlines: {
              color: 'transparent'
          },
      },
      vAxis: {
          format: "short",
          gridlines: {
              color: '#edf1f4',
          },
      },
      // explorer: {
      //     axis: "horizontal",
      //     //   actions: ["dragToZoom", "rightClickToReset"],
      //     keepInBounds: true,
      //     maxZoomIn: 4,
      //     maxZoomOut: 1,
      // },
      legend: "none",
      series: [{'color': '#00b6ef', 'areaOpacity': 0.1}],
      animation: { startup: true, easing: "linear", duration: 1000 },
    };

    useEffect(() => {
      console.log("In Report",shop_domain)
      setLoading(true);
      try {
          if(Cookies.get('shop')){
              // Facebook Loader data
              var created_at_max = new Date();
              var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 30));
              setFilterDate([created_at_min, created_at_max]);
              axios.post(app_url+"/api/google/get_landing_page_optimization_data",{
                  shop_domain:shop_domain,
                  date_start:formatDate(created_at_min),
                  date_end:formatDate(created_at_max),
                  func_call_type:"redis",
              }, {withCredentials:true})
              .then(res => res.data)
              .then(data => {
                  console.log(data)
                  let keyword_level_data = data["data"]["lp_keywords_data"];
                  let ad_group_level_data = data["data"]["lp_ad_group_data"];
                  let lp_performance_keywords_data = data["data"]["lp_performance_keywords_data"];
                  
                  let temp_gb_landing_page_settings = data?.data?.gb_landing_page_urls;
                  setLandingPageSettings(temp_gb_landing_page_settings ? temp_gb_landing_page_settings : {})
                  setAllLandingPageURLS(temp_gb_landing_page_settings ? Object.keys(temp_gb_landing_page_settings) : [])
                  setSelectedLandingPageUrl(temp_gb_landing_page_settings ? Object.keys(temp_gb_landing_page_settings)[0] : "")
                  let temp_suggestions_arr = [];
                  if(temp_gb_landing_page_settings){
                    if(Object.keys(temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["variation_value"]).includes(selected_keywords)){
                      temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["variation_value"][selected_keywords].forEach((gb_ff_value) => {
                        temp_suggestions_arr.push(gb_ff_value);
                      })
                    }
                    else{
                      temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["response_order"].forEach((key) => {
                          temp_suggestions_arr.push("");
                      })
                    }
                  }
                  else{
                    temp_suggestions_arr = [""];
                  }
                  setSelectedSuggestion(temp_suggestions_arr);

                  let temp_all_keywords = get_all_keywords(keyword_level_data)
                  setKeywordPerformanceData(lp_performance_keywords_data)
                  setKeywordPerformanceLineChartAllKeywords1((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
                  setKeywordPerformanceLineChartAllKeywords2((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
                  setSelectedLineChartsKeywords1((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
                  setSelectedLineChartsKeywords2((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
                  setSelectedLineChartsMetrics1({ value: "SUM(CAST(cost AS FLOAT))", label: "Cost" })
                  setSelectedLineChartsMetrics2({ value: "SUM(CAST(cost AS FLOAT))", label: "Cost" })

                  setKeywordPerformanceLineChartData1(get_performance_line_chart_data(lp_performance_keywords_data, 'keyword', (temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "", { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" }))
                  setKeywordPerformanceLineChartData2(get_performance_line_chart_data(lp_performance_keywords_data, 'keyword', (temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "", { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" }))
                  
                  setKeywordLevelData(keyword_level_data);
                  setAdGroupLevelData(ad_group_level_data);
                  setAdGroupLevelData(ad_group_level_data);
                  temp_all_keywords = get_all_keywords(ad_group_level_data)
                  setAllKeywords((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
                  setSelectedKeywords((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
                  setScatterOverviewData(get_scatter_overview_data(keyword_level_data, 'clicks', 'cost', [['All','All']], 'All', "Keyword"))
                  
                  setAnalyzeBtnLoading(false);
                  setGenerateBtnLoading(false);
                  setShowLandingPageURL(false);
                  setTitleXAxis("Cost");
                  setTitleYAxis("Clicks");
                  setXAxis({ value: 'cost', label: 'Cost' });
                  setYAxis({ value: 'clicks', label: 'Clicks' });
                  setPriceRange([["All","All"]]);
                  setAdStrengthValue("All");
                  setScatteredPlotType("Keyword");
                  setSuggestions([]);
                  setTableDataLevel("Ad group");
                  setCustomInput("");

                  setLoading(false)
              })
              .catch(err => {
                  setLoading(false)
                  toast.error(`${errorMessages["DATA_PARSING_ERROR"]["title"]}`);
                  console.log(err);
              })
          }
      } catch (error) {
          setLoading(false)
          toast.error(`${errorMessages["DATA_LOAD_ERROR"]["title"]}`);
          console.log(error);
      }

  }, [app_url, shop_domain]);


  const handleDateChange = async (date_range) => {
    setFilterDate(date_range);
    if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
        setLoading(true)
        var created_at_max = formatDate(date_range[1]);
        var created_at_min =  formatDate(date_range[0]);
        axios.post(app_url+"/api/google/get_landing_page_optimization_data",{
          shop_domain:shop_domain,
          date_start:formatDate(created_at_min),
          date_end:formatDate(created_at_max),
          func_call_type:"db",
      }, {withCredentials:true})
      .then(res => res.data)
      .then(data => {
          console.log(data)
          let keyword_level_data = data["data"]["lp_keywords_data"];
          let ad_group_level_data = data["data"]["lp_ad_group_data"];
          let lp_performance_keywords_data = data["data"]["lp_performance_keywords_data"];

          let temp_gb_landing_page_settings = data?.data?.gb_landing_page_urls;
          setLandingPageSettings(temp_gb_landing_page_settings ? temp_gb_landing_page_settings : {})
          setAllLandingPageURLS(temp_gb_landing_page_settings ? Object.keys(temp_gb_landing_page_settings) : [])
          setSelectedLandingPageUrl(temp_gb_landing_page_settings ? Object.keys(temp_gb_landing_page_settings)[0] : "")
          let temp_suggestions_arr = [];
          if(temp_gb_landing_page_settings){
            if(Object.keys(temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["variation_value"]).includes(selected_keywords)){
              temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["variation_value"][selected_keywords].forEach((gb_ff_value) => {
                temp_suggestions_arr.push(gb_ff_value);
              })
            }
            else{
              temp_gb_landing_page_settings[Object.keys(temp_gb_landing_page_settings)[0]][0]["response_order"].forEach((key) => {
                  temp_suggestions_arr.push("");
              })
            }
          }
          else{
            temp_suggestions_arr = [""];
          }
          setSelectedSuggestion(temp_suggestions_arr);

          
          let temp_all_keywords = get_all_keywords(keyword_level_data)
          setKeywordPerformanceData(lp_performance_keywords_data)
          setKeywordPerformanceLineChartAllKeywords1((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
          setKeywordPerformanceLineChartAllKeywords2((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
          setSelectedLineChartsKeywords1((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
          setSelectedLineChartsKeywords2((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
          setSelectedLineChartsMetrics1({ value: "SUM(CAST(cost AS FLOAT))", label: "Cost" })
          setSelectedLineChartsMetrics2({ value: "SUM(CAST(cost AS FLOAT))", label: "Cost" })
          
          setKeywordPerformanceLineChartData1(get_performance_line_chart_data(lp_performance_keywords_data, 'keyword', (temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "", { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" }))
          setKeywordPerformanceLineChartData2(get_performance_line_chart_data(lp_performance_keywords_data, 'keyword', (temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "", { value: "SUM(CAST(cost AS FLOAT))", label: "Cost" }))
          
          setKeywordLevelData(keyword_level_data);
          setAdGroupLevelData(ad_group_level_data);
          temp_all_keywords = get_all_keywords(ad_group_level_data)
          setAllKeywords((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords : [])
          setSelectedKeywords((temp_all_keywords && temp_all_keywords.length > 0) ? temp_all_keywords[0] : "")
          setScatterOverviewData(get_scatter_overview_data(keyword_level_data, 'clicks', 'cost', [['All','All']], 'All', "Keyword"))
          
          setAnalyzeBtnLoading(false);
          setGenerateBtnLoading(false);
          setShowLandingPageURL(false);
          setTitleXAxis("Cost");
          setTitleYAxis("Clicks");
          setXAxis({ value: 'cost', label: 'Cost' });
          setYAxis({ value: 'clicks', label: 'Clicks' });
          setPriceRange([["All","All"]]);
          setAdStrengthValue("All");
          setScatteredPlotType("Keyword");
          setSuggestions([]);
          setTableDataLevel("Ad group");
          setCustomInput("");

          setLoading(false)
      })
      .catch(err => {
          setLoading(false)
          toast.error(`${errorMessages["DATA_LOAD_ERROR"]["title"]}`);
          console.log(err);
      })
    }
  }


    const handleCustomizePageSubmit = (is_drafted) => {
      if(!is_drafted){
        setApplyCustomizeBtnLoading(true);
      }
      console.log(selected_suggestion)      
      for(let index = 0; index < selected_suggestion.length; index++) {
        let suggestion_iter = selected_suggestion[index];
        console.log(suggestion_iter)
        if(!suggestion_iter || !suggestion_iter.trim()){
          toast.error(`Please add ${landing_page_settings[selected_landing_page_url][0]["response_order"][index]}`);
          if(!is_drafted){
            setApplyCustomizeBtnLoading(false);
          }
          return;
        }
      }
      let temp_selected_keywords =  is_drafted ? `${selected_keywords} (draft_${shop_domain.replace(".myshopify.com","")})`  : selected_keywords;
      axios.post(app_url+"/api/google/gb_feature_flag_update",{
          "landing_page_url":selected_landing_page_url,
          "key":temp_selected_keywords,
          "value":selected_suggestion
      }, {withCredentials:true})
      .then(res => res.data)
      .then(data => {
          console.log(data)
          let temp_suggestions_arr = [];
          if(Object.keys(data["data"]["gb_feature_flag_settings"][selected_landing_page_url][0]["variation_value"]).includes(temp_selected_keywords)){
              data["data"]["gb_feature_flag_settings"][selected_landing_page_url][0]["variation_value"][temp_selected_keywords].forEach((gb_ff_value) => {
              temp_suggestions_arr.push(gb_ff_value);
            })
          }
          else{
            data["data"]["gb_feature_flag_settings"][selected_landing_page_url][0]["response_order"].forEach((key) => {
              temp_suggestions_arr.push("");
            })
          }
          setSelectedSuggestion(temp_suggestions_arr);
          setLandingPageSettings(data?.data?.gb_feature_flag_settings ? data["data"]["gb_feature_flag_settings"] : {})
          if(!is_drafted){
            setApplyCustomizeBtnLoading(false);
            setShowFinalLandingPageURL(true);
          }
        })
        .catch(err => {
          toast.error(`${errorMessages["AI_GENERATION_ERROR"]["title"]}`);
          if(!is_drafted){
            setApplyCustomizeBtnLoading(false);
          }
          console.log(err);
      })
    }
  
    const handleSaveDescriptionToSheet = (e) => {
      e.preventDefault();
      setGenerateBtnLoading(true);  
      for(let index = 0; index < selected_suggestion.length; index++) {
        let suggestion_iter = selected_suggestion[index];
        console.log(suggestion_iter)
        if(!suggestion_iter || !suggestion_iter.trim()){
          toast.error(`Please add ${landing_page_settings[selected_landing_page_url][0]["response_order"][index]}`);
          setGenerateBtnLoading(false);
          return;
        }
      }
      try {
        if(Cookies.get('shop')){
            handleCustomizePageSubmit(true);
            axios.post(app_url+"/api/shopify/send_landing_page_to_sheet",{
              sheet_data: [formatDate(new Date()),selected_keywords,selected_landing_page_url,...selected_suggestion,encodeURI(`${selected_landing_page_url}?sxi_content=${selected_keywords} (draft_${shop_domain.replace(".myshopify.com","")})`)],
            }, {withCredentials:true})
            .then(request => request.data)
            .then(data => {
              toast.success("Added to Sheet")
              setSheetUrl(data["data"]["spreadsheetUrl"]);
              setGenerateBtnLoading(false);
              setShowLandingPageURL(true);
            })
            .catch(err => {
              setGenerateBtnLoading(false);
              toast.error(`${errorMessages["SHEET_UPLOAD_FAILED"]["title"]}`);
              console.log(err)
            });
          }
        }
        catch(err){
          setGenerateBtnLoading(false);
          toast.error(`${errorMessages["SHEET_UPLOAD_FAILED"]["title"]}`);
          console.log(err)
      }
    }

    const handleAnalyze = (e) => {
      e.preventDefault();
      setShowFinalLandingPageURL(false)
      setShowLandingPageURL(false)
      setAnalyzeBtnLoading(true);
      try{
          let new_message = `Keyword : ${selected_keywords} \n\n Using above details, Provide 5 ad copies description in less than 120 characters. Make sure description follows intent of above keyword. And each description uses a different communication theme. Response should in json format. Example, {"Description":["Description 1","Description 2","Description 3","Description 4","Description 5"]}.`;
          if(landing_page_settings && landing_page_settings[selected_landing_page_url] && landing_page_settings[selected_landing_page_url].length > 0){
            new_message = ``;
            if(landing_page_settings[selected_landing_page_url][0]?.lp_business_context){
              new_message += `\n\n ${landing_page_settings[selected_landing_page_url][0]["lp_business_context"]} \n\n`;
            }
            new_message += `\n\n Keyword : ${selected_keywords} \n\n`;
            if(landing_page_settings[selected_landing_page_url][0]?.question_prompt){
              new_message += `${landing_page_settings[selected_landing_page_url][0]["question_prompt"]} \n\n`;
            }
          }
          
          let temp_suggestions_arr = [];
          if(Object.keys(landing_page_settings[selected_landing_page_url][0]["variation_value"]).includes(selected_keywords)){
              landing_page_settings[selected_landing_page_url][0]["variation_value"][selected_keywords].forEach((gb_ff_value) => {
              temp_suggestions_arr.push(gb_ff_value);
            })
          }
          else{
              landing_page_settings[selected_landing_page_url][0]["response_order"].forEach((key) => {
              temp_suggestions_arr.push("");
            })
          }
          setSelectedSuggestion(temp_suggestions_arr);

          axios.post(app_url+"/chats",{
              new_message:new_message, 
              old_message:[],
              format:"json_object"
          }, {withCredentials:true})
          .then(res => res.data)
          .then(data => {
              console.log(JSON.parse(data.data))
              let chatbot_res = JSON.parse(data.data);
              let temp_suggestions_keys = Object.keys(JSON.parse(data.data));
              let temp_suggestions_first_keys = Object.keys(JSON.parse(data.data))[0];
              let temp_suggestions = [];
              if(landing_page_settings && landing_page_settings[selected_landing_page_url] && landing_page_settings[selected_landing_page_url].length > 0){
                if(landing_page_settings[selected_landing_page_url][0]?.response_order){
                  temp_suggestions = [[landing_page_settings[selected_landing_page_url][0]["response_order"]], temp_suggestions_keys.map((val) => { return chatbot_res[val] })].flat();
                }
              }
              else{
                temp_suggestions = [[["Page Descriptions"]] , JSON.parse(data.data)[temp_suggestions_first_keys].map((val) => { return [val] })].flat();
              }
              console.log("Suggestion",temp_suggestions)
              setSuggestions(temp_suggestions)
              setAnalyzeBtnLoading(false)
          })
          .catch(err => {
              setAnalyzeBtnLoading(false)
              toast.error(`${errorMessages["AI_GENERATION_ERROR"]["title"]}`);
              console.log(err);
            })
          }
        catch(error){
          toast.error(`${errorMessages["AI_GENERATION_ERROR"]["title"]}`);
          setAnalyzeBtnLoading(false)
          console.log(error);
      }
  }


    const handleFindMoreVariations = (e) => {
      e.preventDefault();
      setFindMoreVariationsLoading(true);
      try{
          let new_message = `Keyword : ${selected_keywords} \n\n Using above details, Provide 5 ad copies description in less than 120 characters. Make sure description follows intent of above keyword. And each description uses a different communication theme. Response should in json format. Example, {"Description":["Description 1","Description 2","Description 3","Description 4","Description 5"]}.`;
          if(landing_page_settings && landing_page_settings[selected_landing_page_url] && landing_page_settings[selected_landing_page_url].length > 0){
            new_message = ``;
            if(landing_page_settings[selected_landing_page_url][0]?.lp_business_context){
              new_message += `\n\n ${landing_page_settings[selected_landing_page_url][0]["lp_business_context"]} \n\n`;
            }
            new_message += `\n\n Keyword : ${selected_keywords} \n\n`;
            if(landing_page_settings[selected_landing_page_url][0]?.question_prompt){
              new_message += `${landing_page_settings[selected_landing_page_url][0]["question_prompt"]} \n\n`;
            }
          }
          
          let temp_suggestions_arr = [];
          if(Object.keys(landing_page_settings[selected_landing_page_url][0]["variation_value"]).includes(selected_keywords)){
              landing_page_settings[selected_landing_page_url][0]["variation_value"][selected_keywords].forEach((gb_ff_value) => {
              temp_suggestions_arr.push(gb_ff_value);
            })
          }
          else{
              landing_page_settings[selected_landing_page_url][0]["response_order"].forEach((key) => {
              temp_suggestions_arr.push("");
            })
          }
          setSelectedSuggestion(temp_suggestions_arr);

          axios.post(app_url+"/chats",{
              new_message:new_message, 
              old_message:[],
              format:"json_object"
          }, {withCredentials:true})
          .then(res => res.data)
          .then(data => {
              console.log(JSON.parse(data.data))
              let chatbot_res = JSON.parse(data.data);
              let temp_suggestions_keys = Object.keys(JSON.parse(data.data));
              let temp_suggestions_first_keys = Object.keys(JSON.parse(data.data))[0];
              let temp_suggestions = [];
              if(landing_page_settings && landing_page_settings[selected_landing_page_url] && landing_page_settings[selected_landing_page_url].length > 0){
                if(landing_page_settings[selected_landing_page_url][0]?.response_order){
                  temp_suggestions = [temp_suggestions_keys.map((val) => { return chatbot_res[val] })].flat();
                }
              }
              else{
                temp_suggestions = [JSON.parse(data.data)[temp_suggestions_first_keys].map((val) => { return [val] })].flat();
              }
              console.log("Suggestion",temp_suggestions)
              setSuggestions([...suggestions.concat(temp_suggestions)])
              setFindMoreVariationsLoading(false)
          })
          .catch(err => {
            setFindMoreVariationsLoading(false)
              toast.error(`${errorMessages["AI_GENERATION_ERROR"]["title"]}`);
              console.log(err);
            })
          }
        catch(error){
          toast.error(`${errorMessages["AI_GENERATION_ERROR"]["title"]}`);
          setFindMoreVariationsLoading(false)
          console.log(error);
      }
  }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Keyword Intent Optimization</h2>
                            </div>

                            <Container className='my-4'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker shouldDisableDate={combine(before(max_date), afterToday())} editable={false} value={filter_date} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                </Row>
                                <Row className='justify-content-md-left' lg={6} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Data Level</Label>
                                        <ReMultiSelect 
                                            id="data_level"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: scattered_plot_type, label: scattered_plot_type }}
                                            options={[
                                              { value: "Keyword", label: "Keyword" },
                                              { value: "Ad group", label: "Ad group" },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setScatteredPlotType(selected_vals.value);
                                              setXAxis({ value: 'cost', label: 'Cost' });
                                              setYAxis({ value: 'clicks', label: 'Clicks' });
                                              setCurrentMetricOptions(all_scattered_charts_type[selected_vals.value]);
                                              setAdStrengthValue("All");
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">X-Axis</Label>
                                        <ReMultiSelect 
                                            id="x_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={x_axis }
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setXAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Y-Axis</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={ y_axis }
                                            options={current_metric_options}
                                            onChange={(selected_vals) => {
                                              setYAxis(selected_vals);
                                            }}
                                        />
                                    </Col>
                                    {/* <Col className='p-2'>
                                        <Label className="charts_filter_label">Cost Filter</Label>
                                        <ReMultiSelect 
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={[{ value: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All", label: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All" }]}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: '0-50', label: '0-50' },
                                                { value: '50-100', label: '50-100' },
                                                { value: '100-150', label: '100-150' },
                                                { value: '150-200', label: '150-200' },
                                                { value: '200-250', label: '200-250' },
                                                { value: '250-300', label: '250-300' },
                                                { value: '300-350', label: '300-350' },
                                                { value: '350-400', label: '350-400' },
                                                { value: '400-450', label: '400-450' },
                                                { value: '450-500', label: '450-500' },
                                                { value: '500-100000', label: '500+' },
                                            ]}
                                            onChange={(selected_vals) => {
                                                var temp_list = [];
                                                if(selected_vals.value !== "all"){
                                                    temp_list.push([selected_vals.value.split('-')[0], selected_vals.value.split('-')[1]]);
                                                    setPriceRange(temp_list);
                                                }
                                                else{
                                                    setPriceRange(["all","all"]);
                                                }
                                            }}
                                        />
                                    </Col> */}
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Creative Quality Score</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={true}
                                            components={ReMultiSelectAnimated()}
                                            value={{ value: landing_page_exp, label: landing_page_exp }}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: 'BELOW_AVERAGE', label: 'Below average' },
                                                { value: 'AVERAGE', label: 'Average' },
                                                { value: 'ABOVE_AVERAGE', label: 'Above average' },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setAdStrengthValue(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={() => {
                                            setLoading(true);
                                            setTimeout(()=> {
                                              setTitleXAxis(x_axis["label"])
                                              setTitleYAxis(y_axis["label"])
                                              setScatterOverviewData(get_scatter_overview_data(scattered_plot_type === "Keyword" ? keyword_level_data : ad_group_level_data, x_axis["value"], y_axis["value"], price_range, landing_page_exp, scattered_plot_type))
                                              setLoading(false);
                                            }, 500)
                                        }}>Apply</Button>
                                    </Col>
                                </Row>
                            </Container>

                            <div className='cro-overview mb-5'>
                                { scatter_overview_data.length > 0 ?
                                    <Chart
                                        chartType="ScatterChart"
                                        width="100%"
                                        height="500px"
                                        data={scatter_overview_data}
                                        options={{
                                            pointSize:10,
                                            chartArea: {
                                                width: "70%",
                                                height: "80%"
                                            },
                                            hAxis: { 
                                                title:'',
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            vAxis: {
                                              title:'',
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            curveType: "function",
                                            tooltip: { isHtml: true },
                                            legend: {
                                              alignment: 'center',
                                              
                                            },
                                            series: [
                                              { color: 'darkgreen', labelInLegend: 'Above average' },
                                              { color: 'red', labelInLegend: 'Below average' },
                                              { color: 'yellow', labelInLegend: 'Average' },
                                              { color: 'orange', labelInLegend: '--' },
                                            ]
                                        }}
                                    />
                                    :
                                    "No data to Show."
                                }
                            </div>

                            <Accordion alwaysOpen defaultActiveKey={["1"]}>
                              { (keyword_level_data && keyword_level_data.length > 0) &&
                                <Accordion.Item eventKey="0" style={{border:"none"}}>
                                  <Accordion.Header style={{borderBottom:"2px solid #edf1f4"}}><h6 style={{fontSize:"1.15rem",margin:"0"}}>Experience Customization</h6></Accordion.Header>
                                  <Accordion.Body style={{backgroundColor:"#edf1f4"}}>
                                    <Container className='m-2'>
                                        <Row className='justify-content-md-left' lg={(all_landing_page_urls && all_landing_page_urls.length > 0) ? 5 : 4} xs={1}>
                                          <Col className='p-2'>
                                            <Label className="charts_filter_label">Data Level</Label>
                                            <ReMultiSelect 
                                                className='popup_filter_include_exclude'
                                                closeMenuOnSelect={true}
                                                components={ReMultiSelectAnimated()}
                                                value={{ value: table_data_level, label: table_data_level }}
                                                options={[
                                                  { value: "Keyword", label: "Keyword" },
                                                  { value: "Ad group", label: "Ad group" },
                                                ]}
                                                onChange={(selected_vals) => {
                                                  setTableDataLevel(selected_vals.value);
                                                  let temp_keywords = get_all_keywords(selected_vals.value === "Keyword" ? keyword_level_data : ad_group_level_data);
                                                  setAllKeywords((temp_keywords && temp_keywords.length > 0) ? temp_keywords : [])
                                                  setSelectedKeywords((temp_keywords && temp_keywords.length > 0) ? temp_keywords[0] : "")
                                                }}
                                            />
                                          </Col>
                                          <Col className='p-2'>
                                            <Label className="charts_filter_label">Keyword</Label>
                                            <ReMultiSelect 
                                                id="select_keyword"
                                                className='popup_filter_include_exclude'
                                                closeMenuOnSelect={true}
                                                components={ReMultiSelectAnimated()}
                                                value={{ value: selected_keywords, label: selected_keywords }}
                                                options={all_keywords.map((val) => { return { value: val, label: val }})}
                                                onChange={(selected_vals) => {
                                                  setSelectedKeywords(selected_vals.value);
                                                }}
                                            />
                                          </Col>
                                          { (all_landing_page_urls && all_landing_page_urls.length > 0) &&
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Landing Page Url</Label>
                                              <ReMultiSelect 
                                                  id="lp_urls"
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={{ value: selected_landing_page_url, label: selected_landing_page_url }}
                                                  options={all_landing_page_urls.map((val) => { return { value: val, label: val }})}
                                                  onChange={(selected_vals) => {
                                                    setSelectedLandingPageUrl(selected_vals.value);
                                                  }}
                                              />
                                            </Col>
                                          }
                                          <Col className='p-2'>
                                              <Label className="charts_filter_label">Experience Customization Status</Label>
                                              <p className='m-0'>
                                                { (landing_page_settings && landing_page_settings[selected_landing_page_url] && landing_page_settings[selected_landing_page_url][0] && landing_page_settings[selected_landing_page_url][0]["variation_value"] && Object.keys(landing_page_settings[selected_landing_page_url][0]["variation_value"]).includes(selected_keywords)) ?
                                                    <p className='m-0' style={{display:"inline-block",border:"1px solid #cdcdcd",backgroundColor:"white",width:"100%",borderRadius:"5px",padding:"6px 15px"}}>Enabled</p>
                                                  :
                                                    <p className='m-0' style={{display:"inline-block",border:"1px solid #cdcdcd",backgroundColor:"white",width:"100%",borderRadius:"5px",padding:"6px 15px"}}>Not Yet Enabled</p>
                                                }
                                              </p>
                                          </Col>
                                          <Col className='p-2 d-flex align-items-end'>
                                            {analyze_btn_loading ?
                                              <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} disabled><Spinner animation="border"/></Button>
                                              :
                                              <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={handleAnalyze}>Analyze</Button>
                                            }
                                        </Col>
                                      </Row>

                                      
                                      { (analyze_btn_loading || find_more_variation_loading) ?
                                        <div className='d-flex align-items-center justify-content-center' style={{height:"300px"}}>
                                          <Spinner animation="border"/>
                                        </div>
                                      :
                                      <>
                                          {suggestions.length > 0 &&
                                            <>
                                              <Row className='justify-content-md-left mt-3' style={{maxHeight: "400px", overflow: "scroll", maxWidth: "100%"}} lg={1} xs={1}>
                                                <Table responsive striped={false} bordered hover={false} >
                                                  <thead>
                                                    <tr>
                                                      {suggestions[0].map((val) => {
                                                        return (
                                                          <th style={{width:`${suggestions[0].length >= 3 ? "33vw" : "50vw"}`}}>{val}</th>
                                                        )
                                                      })}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {suggestions.slice(1).map((row, row_index) => {
                                                      return (<tr>
                                                        {row.map((val) =>{
                                                          return (
                                                            <td style={{width:`${suggestions[0].length >= 3 ? "33vw" : "50vw"}`}}>{val}</td>
                                                          )
                                                        })}
                                                      </tr>)
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </Row>

                                              <Row className='justify-content-md-left mt-1' lg={1} xs={1}>
                                                <Col style={{textAlign:"left"}}>
                                                    <Button style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500', width:"fit-content"}} onClick={handleFindMoreVariations}>Find More Variations</Button>
                                                </Col>
                                              </Row>

                                              <Row className='justify-content-md-left mt-4' lg={suggestions[0].length >= 3 ? 3 : 2} xs={1}>
                                                    { suggestions[0].map((val, index) => {
                                                          return (
                                                            <Col>
                                                              <label id={"custom_description_"+index} style={{fontWeight:"bold"}}>{val}</label>
                                                              <br/>
                                                              <textarea
                                                                  id={"custom_description_"+index}
                                                                  style={{width:"100%"}}
                                                                  className='mt-2 custom-input-landing-page'
                                                                  placeholder="Type here ..."
                                                                  value={selected_suggestion[index]}
                                                                  onChange={(e) =>
                                                                    {
                                                                      let temp_selected_suggestion = [...selected_suggestion];
                                                                      temp_selected_suggestion[index] = e.target.value;
                                                                      setSelectedSuggestion(temp_selected_suggestion);
                                                                    } 
                                                                  }
                                                              />
                                                            </Col>
                                                          )
                                                      })
                                                    }
                                              </Row>

                                              <Row className='justify-content-md-left mt-3' lg={2} xs={1}>
                                                <Col style={{textAlign:"left"}}>
                                                  {generate_btn_loading ?
                                                    <Button className='mt-3' variant='success' disabled><Spinner animation="border" /></Button>
                                                  :
                                                  <>
                                                    {/* <Button className='mt-3' variant='success' onClick={handleCustomizePageSubmit}>Customize Page</Button> */}
                                                    <Button className='mt-3' variant='success' onClick={handleSaveDescriptionToSheet}>Generate Preview & Add to Sheet</Button>
                                                  </>
                                                  }
                                                </Col>
                                              </Row>

                                              <Row className='justify-content-md-left mt-3' lg={1} xs={1}>
                                                {show_landing_page &&
                                                  <div className='px-3 py-4 mt-4' style={{width:"100%", backgroundColor:"white", color:"black", borderRadius:"5px"}} >
                                                    Landing Page URL (Open in incognito mode) - <Link to={encodeURI(`${selected_landing_page_url}?sxi_content=${selected_keywords} (draft_${shop_domain.replace(".myshopify.com","")})`)} target='_blank'>{encodeURI(`${selected_landing_page_url}?sxi_content=${selected_keywords} (draft_${shop_domain.replace(".myshopify.com","")})`)}</Link>
                                                    <br/>
                                                    <br/>
                                                    Spreadsheet Url - <Link to={spreadsheet_url} target='_blank'>{spreadsheet_url}</Link>
                                                  </div>
                                                }
                                              </Row>


                                              <Row className='justify-content-md-left mt-3' lg={1} xs={1}>
                                                {show_landing_page &&
                                                  <>
                                                    <Col style={{textAlign:"left"}}>
                                                      {apply_customization_btn_loading ?
                                                        <Button className='mt-3' variant='success' disabled><Spinner animation="border" /></Button>
                                                      :
                                                      <>
                                                        <Button className='mt-3' variant='success' onClick={(e) => {handleCustomizePageSubmit(false)}}>Apply</Button>
                                                      </>
                                                      }
                                                    </Col>
                                                    {show_final_landing_page &&
                                                      <div className='px-3 py-4 mt-4' style={{width:"100%", backgroundColor:"white", color:"black", borderRadius:"5px"}} >
                                                        Final Landing Page URL (Open in incognito mode) - <Link to={encodeURI(`${selected_landing_page_url}?sxi_content=${selected_keywords}`)} target='_blank'>{encodeURI(`${selected_landing_page_url}?sxi_content=${selected_keywords}`)}</Link>
                                                      </div>
                                                    }
                                                  </>
                                                }
                                              </Row>

                                            </>
                                          }
                                      </>
                                      }
                                    </Container>
                                  </Accordion.Body>
                                </Accordion.Item>
                              }
                              { (keyword_performance_data && keyword_performance_data.length > 0) &&
                                <Accordion.Item eventKey="1" style={{border:"none"}}>
                                  <Accordion.Header><h6 style={{fontSize:"1.15rem",margin:"0"}}>Performance Measurement</h6></Accordion.Header>
                                  <Accordion.Body style={{backgroundColor:"#edf1f4"}}>
                                    <Container>
                                    <Row sm={1} xs={1}>
                                        <Col className='mb-4'>
                                          <Row className='mb-2' sm={5} xs={1}>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Data Level</Label>
                                              <ReMultiSelect 
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={{ value: keyword_performance_line_chart_type_1, label: keyword_performance_line_chart_type_1 }}
                                                  options={[
                                                    { value: "Keyword", label: "Keyword" },
                                                    { value: "Ad group", label: "Ad group" },
                                                  ]}
                                                  onChange={(selected_vals) => {
                                                    setKeywordPerformanceLineChartType1(selected_vals.value);
                                                    let temp_keywords = get_all_keywords(selected_vals.value === "Keyword" ? keyword_level_data : ad_group_level_data);
                                                    setKeywordPerformanceLineChartAllKeywords1((temp_keywords && temp_keywords.length > 0) ? temp_keywords : [])
                                                    setSelectedLineChartsKeywords1((temp_keywords && temp_keywords.length > 0) ? temp_keywords[0] : "");
                                                    setKeywordPerformanceLineChartData1(get_performance_line_chart_data(keyword_performance_data, selected_vals.value, (temp_keywords && temp_keywords.length > 0) ? temp_keywords[0] : "", selected_line_chart_metrics_1))
                                                  }}
                                              />
                                            </Col>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Keyword</Label>
                                              <ReMultiSelect 
                                                  id="select_keyword"
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={{ value: selected_line_chart_keyword_1, label: selected_line_chart_keyword_1 }}
                                                  options={keyword_performance_line_chart_all_keywords_1.map((val) => { return { value: val, label: val }})}
                                                  onChange={(selected_vals) => {
                                                    setSelectedLineChartsKeywords1(selected_vals.value);
                                                    setKeywordPerformanceLineChartData1(get_performance_line_chart_data(keyword_performance_data, keyword_performance_line_chart_type_1, selected_vals.value, selected_line_chart_metrics_1))
                                                  }}
                                              />
                                            </Col>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Metric</Label>
                                              <ReMultiSelect 
                                                  id="select_keyword"
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={selected_line_chart_metrics_1}
                                                  options={keyword_performance_line_chart_metrics_1}
                                                  onChange={(selected_vals) => {
                                                    setSelectedLineChartsMetrics1(selected_vals);
                                                    setKeywordPerformanceLineChartData1(get_performance_line_chart_data(keyword_performance_data, keyword_performance_line_chart_type_1, selected_line_chart_keyword_1, selected_vals))
                                                  }}
                                              />
                                            </Col>
                                          </Row>
                                            { keyword_performance_line_chart_data_1.length > 0 ?
                                              <Chart
                                                  chartType="AreaChart"
                                                  width={"100%"}
                                                  height={"300px"}
                                                  data={keyword_performance_line_chart_data_1}
                                                  options={line_charts_config}
                                              />
                                              :
                                              "No data to show"
                                            }
                                        </Col>
                                        <Col>
                                        <Row className='mb-2' sm={5} xs={1}>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Data Level</Label>
                                              <ReMultiSelect 
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={{ value: keyword_performance_line_chart_type_2, label: keyword_performance_line_chart_type_2 }}
                                                  options={[
                                                    { value: "Keyword", label: "Keyword" },
                                                    { value: "Ad group", label: "Ad group" },
                                                  ]}
                                                  onChange={(selected_vals) => {
                                                    setKeywordPerformanceLineChartType2(selected_vals.value);
                                                    let temp_keywords = get_all_keywords(selected_vals.value === "Keyword" ? keyword_level_data : ad_group_level_data);
                                                    setKeywordPerformanceLineChartAllKeywords2((temp_keywords && temp_keywords.length > 0) ? temp_keywords : [])
                                                    setSelectedLineChartsKeywords2((temp_keywords && temp_keywords.length > 0) ? temp_keywords[0] : "");
                                                    setKeywordPerformanceLineChartData2(get_performance_line_chart_data(keyword_performance_data, selected_vals.value, (temp_keywords && temp_keywords.length > 0) ? temp_keywords[0] : "", selected_line_chart_metrics_2))
                                                  }}
                                              />
                                            </Col>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Keyword</Label>
                                              <ReMultiSelect 
                                                  id="select_keyword"
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={{ value: selected_line_chart_keyword_2, label: selected_line_chart_keyword_2 }}
                                                  options={keyword_performance_line_chart_all_keywords_2.map((val) => { return { value: val, label: val }})}
                                                  onChange={(selected_vals) => {
                                                    setSelectedLineChartsKeywords2(selected_vals.value);
                                                    setKeywordPerformanceLineChartData2(get_performance_line_chart_data(keyword_performance_data, keyword_performance_line_chart_type_2, selected_vals.value, selected_line_chart_metrics_2))
                                                  }}
                                              />
                                            </Col>
                                            <Col className='p-2'>
                                              <Label className="charts_filter_label">Metric</Label>
                                              <ReMultiSelect 
                                                  id="select_keyword"
                                                  className='popup_filter_include_exclude'
                                                  closeMenuOnSelect={true}
                                                  components={ReMultiSelectAnimated()}
                                                  value={selected_line_chart_metrics_2}
                                                  options={keyword_performance_line_chart_metrics_2}
                                                  onChange={(selected_vals) => {
                                                    setSelectedLineChartsMetrics2(selected_vals);
                                                    setKeywordPerformanceLineChartData2(get_performance_line_chart_data(keyword_performance_data, keyword_performance_line_chart_type_2, selected_line_chart_keyword_2, selected_vals))
                                                  }}
                                              />
                                            </Col>
                                          </Row>
                                          { keyword_performance_line_chart_data_2.length > 0 ?
                                              <Chart
                                                  chartType="AreaChart"
                                                  width={"100%"}
                                                  height={"300px"}
                                                  data={keyword_performance_line_chart_data_2}
                                                  options={line_charts_config}
                                              />
                                              :
                                              "No data to show"
                                            }
                                        </Col>
                                      </Row>
                                    </Container>
                                  </Accordion.Body>
                                </Accordion.Item>
                              }
                            </Accordion>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default LandingPageOptimization


function jsonToArrayObject(res, graph_columns) {
  var columns = Object.keys(res[0]);
  var resultArray = res.map(function (obj) {
    var res_arr = [];
    for (let column_name of columns) {
      res_arr.push(obj[column_name]);
    }
    return res_arr;
  });
  resultArray.unshift(graph_columns)
  console.log(resultArray)
 return resultArray;
}


function get_all_keywords(keyword_level_data){
  var res = alasql(`SELECT ARRAY(DISTINCT keyword_text) as all_keywords FROM ? WHERE ([creative_quality_score] in ('BELOW_AVERAGE','AVERAGE')) AND ([campaign_name] NOT LIKE '%competition%') AND ([ad_group_name] NOT LIKE '%competition%') AND ([campaign_name] NOT LIKE '%Competition%') AND ([ad_group_name] NOT LIKE '%Competition%') AND ([keyword_text] NOT LIKE '%Plan B%') AND ([keyword_text] NOT LIKE '%plan b%') AND ([keyword_text] NOT LIKE '%Plan b%') AND ([keyword_text] NOT LIKE '%plan B%')`, [keyword_level_data]);
  console.log(res[0]["all_keywords"]);
  return res[0]["all_keywords"];
}


function get_scatter_overview_data(keyword_level_data, x_axis, y_axis, price_range, landing_page_exp, scattered_plot_type){
    var filtered_data = keyword_level_data;

    // if(price_range.length > 0){
    //   for(let price of price_range){
    //     if( price[0] !== "All"){
    //       filtered_data = alasql(`SELECT * FROM ? WHERE (([cost] >= ${price[0]}) AND ([cost] <= ${price[1]}))`, [filtered_data]);
    //     }
    //   }
    // }
    
    if(landing_page_exp !== "All"){
      filtered_data = alasql(`SELECT * FROM ? WHERE [creative_quality_score] = '${landing_page_exp}'`, [filtered_data]);
    }
    

    var res = alasql(`SELECT * FROM ?`, [filtered_data]);
    if(res.length > 0){
      var final_res = [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]]
      for(let obj of res ){
          final_res.push([
              obj[x_axis],
              
              obj["creative_quality_score"] === "ABOVE_AVERAGE" ? obj[y_axis] : null,
              `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,
                obj["creative_quality_score"] === "BELOW_AVERAGE" ? obj[y_axis] : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Creative Quality Score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                obj["creative_quality_score"] === "AVERAGE" ? obj[y_axis] : null,              
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `,

                (obj["creative_quality_score"] === "--") || (obj["creative_quality_score"] === "nan") ? obj[y_axis] : null,
                `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["ad_group_name"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">creative_quality_score : <b>${obj["creative_quality_score"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Keyword : <b>${obj["keyword_text"]}</b></p>
                  ${all_scattered_charts_type[scattered_plot_type].map((item, index) => {
                    return '<p style="margin:3px; font-size:medium;">'+ item["label"] +': <b>'+parseFloat(obj[item["value"]]).toFixed(1)+'</b></p>';
                  }).join('\n')}
              </div>
              `]);
      }
      console.log(final_res);
      return final_res;
    }
    return [[{label:x_axis,type:"number"},{label:"Above average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Below average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Average",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"--",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}]];
}


function get_filter_date_from_data(data){
  let min_max_date_dict = alasql(`SELECT MIN(DATE(date_start)) as min_date, MAX(DATE(date_start)) as max_date FROM ?`,[data])[0];
  return [min_max_date_dict["min_date"], min_max_date_dict["max_date"]];
}


function formatDate(date, is_increase_date=false) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


function get_performance_line_chart_data(lp_performance_data, data_level, keywords, metrics){
  let res = alasql(`SELECT * FROM ? WHERE keyword_text = "${keywords}"`,[lp_performance_data]);
  res = alasql(`SELECT DATE(date) as date, ${metrics["value"]} AS [${metrics["label"]}] FROM ? GROUP BY date ORDER BY date ASC`,[res]);
  console.log("Res",res)
  if(res.length === 0){
    return []
  }
  return jsonToArrayObject(res, [{"type":"date","label":"Date"}, metrics["label"]]);
}