import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { DateRangePicker } from "rsuite";
import alasql from "alasql";
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function FeedEnrichment({ViewedPage, shop_domain, app_url}) {
    ViewedPage(7);

    const [loading, setLoading] = useState(true);
    const [selected_date_range_value, setDateRange] = useState([]);
    const [order_master_database, setMasterDatabase] = useState([]);

    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
                setDateRange([created_at_min, created_at_max]);
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    let currency_code = get_currency_code(formated_data);
                    setMasterDatabase(get_master_database(formated_data, currency_code));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });                
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }, [app_url, shop_domain]);


    const handleDateChange = async (date_range) => {
        setDateRange(date_range);
        var created_at_max = new Date();
        var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 60));
        if(date_range.length > 0){
            created_at_min = date_range[0];
            created_at_max = date_range[1];
        }
        
        setLoading(true);

        try {
            if(Cookies.get('shop')){
                axios.post(app_url+"/api/shopify/get_shopify_order_data",{
                    shop_domain:Cookies.get('shop'),
                    created_at_min:formatDate(created_at_min),
                    created_at_max:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(request => request.data)
                .then(data => {
                    var formated_data = formate_shopify_data(data.data.order_data);
                    let currency_code = get_currency_code(formated_data);
                    setMasterDatabase(get_master_database(formated_data, currency_code));
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    toast.error("No Data to Show.");
                    console.log(err)
                });
            }
        }
        catch(err){
            toast.error("No Data to Show.");
            console.log(err)
        }
    }


    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Feed Enrichment</h2>
                            </div>

                            <Container className='mt-1'>
                                <Row className='justify-content-md-left' lg={3} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker id='date_range' defaultValue={selected_date_range_value} format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                </Row>
                            </Container>

                            <div className='main-accordion-body p-2' id='product-cataloging'>
                                <Chart
                                    chartType="Table"
                                    width={"100%"}
                                    data={order_master_database}
                                    options={{
                                        width: '100%',
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                        // sort: "disable",
                                        pageSize:10,
                                        cssClassNames:{
                                            tableCell :"channel_performance_table_cell",
                                            headerCell :"channel_performance_header_cell",
                                            tableRow :"channel_performance_table_row", 
                                            oddTableRow :"channel_performance_old_table_row" 
                                        }
                                    }}
                                />
                            </div>
                            
                            </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default FeedEnrichment


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function jsonToArrayObject(res, graph_columns) {
    var columns = Object.keys(res[0]);
    var resultArray = res.map(function (obj) {
      var res_arr = [];
      for (let column_name of columns) {
        res_arr.push(obj[column_name]);
      }
      return res_arr;
    });
    resultArray.unshift(graph_columns)
   return resultArray;
}

function formate_shopify_data(data){
    var res = alasql('SELECT country, created_at, getDateString(created_at) as Date, currency_code, CAST(current_total_price AS FLOAT) as current_total_price, customer_id, customer_joining_date, customer_tags, discount_code, discount_codes, id, last_visit_campaign as utm_campaign, last_visit_content as utm_content, last_visit_medium as utm_medium, last_visit_source as utm_source, last_visit_term as utm_term, product_title, product_type, profit, sku, tags, CAST(total_price AS FLOAT) as total_price FROM ?',[data]);
    
    return res;
}
    

function get_currency_code(data){
    var res = alasql(`SELECT FIRST(currency_code) as currency_code FROM ? `,[data]);
    return res[0]["currency_code"];
}

    
function get_high_revenue_product(data, currency_code){
    let res = alasql('SELECT product_title, SUM(total_price) as total_price, SUM(current_total_price) as current_total_price, SUM(profit) as profit, COUNT(id) as no_of_orders FROM ? GROUP BY product_title',[data]);
    let final_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of iter["product_title"].split("<br />")){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict["product_title"] = filter_on_iter;
            filter_on_and_value_dict["total_price"] = iter["total_price"];
            filter_on_and_value_dict["current_total_price"] = iter["current_total_price"];
            filter_on_and_value_dict["profit"] = iter["profit"];
            filter_on_and_value_dict["no_of_orders"] = iter["no_of_orders"];
            final_res.push(filter_on_and_value_dict);
        }
    }

    final_res = alasql(`SELECT product_title, ROUND(SUM(total_price), 1) as revenue, ROUND(SUM(current_total_price), 1) as net_revenue, ROUND(SUM(profit), 1) as profit, SUM(no_of_orders) as no_of_orders FROM ? GROUP BY product_title`,[final_res]);
            
    return final_res
}

    
function get_high_margin_product(data){
    let res = alasql('SELECT product_title, SUM(total_price) as total_price, SUM(current_total_price) as current_total_price, SUM(profit) as profit, COUNT(id) as no_of_orders FROM ? GROUP BY product_title',[data]);
    let final_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of iter["product_title"].split("<br />")){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict["product_title"] = filter_on_iter;
            filter_on_and_value_dict["total_price"] = iter["total_price"];
            filter_on_and_value_dict["current_total_price"] = iter["current_total_price"];
            filter_on_and_value_dict["profit"] = iter["profit"];
            filter_on_and_value_dict["no_of_orders"] = iter["no_of_orders"];
            final_res.push(filter_on_and_value_dict);
        }
    }

    final_res = alasql(`SELECT product_title, (ROUND(((SUM(profit)/SUM(total_price))*100),1) + " %") as margin FROM ? GROUP BY product_title`,[final_res]);
            
    return final_res
}

    
function get_high_repeat_product(data){
    let res = alasql('SELECT * FROM ? ORDER BY customer_id, created_at',[data]);
    res = alasql(`SELECT customer_id, FIRST(product_title) as product_title, COUNT(id) as no_of_orders FROM ? GROUP BY customer_id`,[res]);
    res = alasql(`SELECT product_title, COUNT(case when no_of_orders > 1 then customer_id else null end) as repeat_customer, COUNT(customer_id) as total_customer FROM ? GROUP BY product_title`,[res]);
    let final_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of iter["product_title"].split("<br />")){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict["product_title"] = filter_on_iter;
            filter_on_and_value_dict["repeat_customer"] = iter["repeat_customer"];
            filter_on_and_value_dict["total_customer"] = iter["total_customer"];
            final_res.push(filter_on_and_value_dict);
        }
    }

    final_res = alasql(`SELECT product_title, COALESCE(ROUND(((SUM(repeat_customer)/SUM(total_customer))*100),1) + " %") as repeat_per FROM ? GROUP BY product_title`,[final_res]);
            
    return final_res;
}


function get_product_selling_on_google(data){
    let res = alasql(`SELECT * FROM ? WHERE utm_source IN ('google','Google','ggl')`,[data]);
    res = alasql(`SELECT product_title FROM ? GROUP BY product_title`,[res]);
    let final_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of iter["product_title"].split("<br />")){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict["product_title"] = filter_on_iter;
            filter_on_and_value_dict["selling_on_google"] = "Yes";
            final_res.push(filter_on_and_value_dict);
        }
    }
    
    final_res = alasql(`SELECT product_title, FIRST(selling_on_google) as selling_on_google FROM ? GROUP BY product_title`,[final_res]);
            
    return final_res;
}


function get_product_selling_on_meta(data){
    let res = alasql(`SELECT * FROM ? WHERE utm_source IN ('facebook','fb','meta','Facebook','Meta','ig','instagram','Instagram')`,[data]);
    res = alasql(`SELECT product_title FROM ? GROUP BY product_title`,[res]);
    let final_res = [];
    
    for(const iter of res){
        for(const filter_on_iter of iter["product_title"].split("<br />")){
            let filter_on_and_value_dict = {}
            filter_on_and_value_dict["product_title"] = filter_on_iter;
            filter_on_and_value_dict["selling_on_meta"] = "Yes";
            final_res.push(filter_on_and_value_dict);
        }
    }
    
    final_res = alasql(`SELECT product_title, FIRST(selling_on_meta) as selling_on_meta FROM ? GROUP BY product_title`,[final_res]);
            
    return final_res;
}


function get_master_database(formated_data, currency_code){
    let high_revenue_product = get_high_revenue_product(formated_data, currency_code)
    let high_margin_product = get_high_margin_product(formated_data)
    let master_database = alasql(`SELECT * FROM ? a LEFT JOIN ? b ON a.product_title = b.product_title`,[high_revenue_product, high_margin_product]);
    let high_repeat_product = get_high_repeat_product(formated_data)
    master_database = alasql(`SELECT * FROM ? a LEFT JOIN ? b ON a.product_title = b.product_title`,[master_database, high_repeat_product]);
    let product_selling_on_google = get_product_selling_on_google(formated_data)
    master_database = alasql(`SELECT * FROM ? a LEFT JOIN ? b ON a.product_title = b.product_title`,[master_database, product_selling_on_google]);
    let product_selling_on_meta = get_product_selling_on_meta(formated_data)
    master_database = alasql(`SELECT * FROM ? a LEFT JOIN ? b ON a.product_title = b.product_title`,[master_database, product_selling_on_meta]);

    master_database = alasql(`SELECT product_title, COALESCE(revenue, 0) as revenue, COALESCE(net_revenue, 0) as net_revenue, COALESCE(profit, 0) as profit, COALESCE(no_of_orders, 0), COALESCE(margin, '0 %'), COALESCE(repeat_per, '0 %'), COALESCE(selling_on_google, 'No'), COALESCE(selling_on_meta,'No') FROM ?`,[master_database]);

    return jsonToArrayObject(master_database ,['Product','Revenue','Net Revenue','Profit','Volume','Margin (in %)','Repeat %', 'Selling on Google', 'Selling on Meta']);
}