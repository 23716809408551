import React, {useState,useEffect} from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import { DateRangePicker } from "rsuite";
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import FormSelect from 'react-bootstrap/FormSelect';
import axios from 'axios';
import Cookies from 'js-cookie';
import {showToast as toast} from '../toastManager';
import alasql from 'alasql';


function MetaCreativeAnalysis({ViewedPage, shop_domain, app_url}) {
    ViewedPage(16);

    const [loading, setLoading] = useState(true);
    const [chart_loading, setChartLoading] = useState(false);
    const [filter_date, setFilterDate] = useState([]);
    const [all_creative_data, setCreativeData] = useState([]);
    const [all_insights_data, setInsightsData] = useState([]);
    const [all_dropdown_options, setDropdownOptions] = useState([]);
    const [selected_dropdown_1, setDropdown1] = useState({value:"tactics",label:"Tactics"});
    const [selected_dropdown_2, setDropdown2] = useState([]);


    useEffect(() => {
        setLoading(true);
        try {
            if(Cookies.get('shop')){
                // Facebook Loader data
                var created_at_max = new Date();
                var created_at_min = new Date(new Date().setDate(created_at_max.getDate() - 15));
                setFilterDate([created_at_min, created_at_max]);
                axios.post(app_url+"/api/facebook/get_meta_creatives_data_from_redis",{
                    shop_domain:Cookies.get('shop'),
                    date_start:formatDate(created_at_min),
                    date_end:formatDate(created_at_max)
                }, {withCredentials:true})
                .then(res => res.data)
                .then(data => {
                    console.log(data)
                    setCreativeData(data["data"]["creative_data"]);
                    setInsightsData(data["data"]["insights_data"]);
                    setFilterDate(get_filter_date_from_data(data["data"]["insights_data"]));
                    setDropdownOptions(get_dropdown_options(data["data"]["insights_data"]))
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    toast.error("Please try after some time.");
                    console.log(err);
                })
            }
        } catch (error) {
            setLoading(false)
            toast.error("Please try after some time.");
            console.log(error);
        }

    }, [app_url, shop_domain]);



    const handleDateChange = async (date_range) => {
        setChartLoading(true)
        if((date_range !== undefined) && (date_range !== null) && (date_range.length > 0)){
            setFilterDate(date_range);
            axios.post(app_url+"/api/facebook/get_meta_creatives_data",{
                shop_domain:Cookies.get('shop'),
                date_start:formatDate(date_range[0]),
                date_end:formatDate(date_range[1])
            }, {withCredentials:true})
            .then(res => res.data)
            .then(data => {
                console.log(data)
                setCreativeData(data["data"]["creative_data"]);
                setInsightsData(data["data"]["insights_data"]);
                setChartLoading(false)
            })
            .catch(err => {
                setChartLoading(false)
                toast.error("Please try after some time.");
                console.log(err);
            })
        }
        else{
            setFilterDate([]);
        }
    }

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Analytics</h2>
                            </div>

                            <Container className='my-4'>
                                <Row className='justify-content-md-left' lg={4} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Time Period</Label>
                                        <br />
                                        <DateRangePicker defaultValue={filter_date} id='date_range' format="yyyy-MM-dd" character=" - " showOneCalendar block onChange={handleDateChange} />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Category</Label>
                                        <br />
                                        <ReMultiSelect 
                                            id="scattered_tags_value"
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            defaultValue={selected_dropdown_1}
                                            options={[
                                                {"value":"tactics","label": "Tactics"},
                                                {"value":"campaign_name","label": "Campaign Name"},
                                                {"value":"adset_name","label": "Adset Name"},
                                            ]}
                                            onChange={(selected_vals) => {
                                                setDropdown1(selected_vals)
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Subcategory</Label>
                                        <br />
                                        <ReMultiSelect 
                                            id="scattered_tags_value"
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            isMulti
                                            options={all_dropdown_options[selected_dropdown_1["value"]]}
                                            onChange={(selected_vals) => {
                                                setDropdown2(selected_vals)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default MetaCreativeAnalysis


function formatDate(date, is_increase_date=false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (is_increase_date? d.getDate() + 1: d.getDate()),
      year = d.getFullYear();
  
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
  
    return [year, month, day].join('-');
}

function get_filter_date_from_data(data){
    let min_max_date_dict = alasql(`SELECT MIN(DATE(date_start)) as min_date, MAX(DATE(date_start)) as max_date FROM ?`,[data])[0];
    return [min_max_date_dict["min_date"], min_max_date_dict["max_date"]];
}

function get_dropdown_options(insights_data){
    let dropdown_options = {
        "tactics":[
            {value:"Remarkeitng",label:"Remarkeitng"},
            {value:"Prospecting",label:"Prospecting"},
            {value:"TOFU",label:"TOFU"},
            {value:"MOFU",label:"MOFU"},
        ],
        "campaign_name":[],
        "adset_name":[],
    };
    let campaign_name_arr = alasql(`SELECT ARRAY(DISTINCT campaign_name) AS campaign_name FROM ?`,[insights_data])[0]["campaign_name"];
    campaign_name_arr.forEach((val) => {
        dropdown_options["campaign_name"].push({value:val,label:val})
    })
    let adset_name_arr = alasql(`SELECT ARRAY(DISTINCT adset_name) AS adset_name FROM ?`,[insights_data])[0]["adset_name"];
    adset_name_arr.forEach((val) => {
        dropdown_options["adset_name"].push({value:val,label:val})
    })
    return dropdown_options
}