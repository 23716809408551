import React from "react"

export default function CohortCharts(props){
    var cohort_by = props.cohort_by;
    // var currency_code = props.currency_code;
    var cohort_header = props.data.length > 0 ? props.data[0] : [];
    var cohort_body = props.data.length > 1 ? props.data.slice(1) : [] ;
    return (
        <div className="cohort-charts-main-container">
            <div style={{display:"flex"}}>
                <div style={{width:"50%", textAlign:"center"}}></div>
                <div style={{width:"50%", textAlign:"center"}}><h6 style={{fontWeight:"500"}}>{cohort_by}</h6></div>
            </div>
            <table className="cohort-charts-table">
                <thead className="cohort-charts-table-head">
                    <tr className="cohort-charts-table-head-tr">
                        {cohort_header.map((iter, idx) => <th className={`cohort-charts-table-head-tr-th ${idx > 3 ? "cohort-values": ""}`}>{iter}</th>)}
                    </tr>
                </thead>
                <tbody className="cohort-charts-table-body">
                    {cohort_body.map((body_iter) => <tr className="cohort-charts-table-body-tr">
                        {body_iter.map((iter, idx) => <td className={`cohort-charts-table-body-tr-td ${idx === 0 ? "cohort-labels": (idx === 1 ? "new-customer-col" : (idx === 2 ? "cohort-repeat-per": (idx === 3 ? "cohort-ltv" : "cohort-values")))} ${ idx>3? "cohort-value-shade-"+Math.ceil( (iter - Math.min(...body_iter.slice(4))) / ((Math.max(...body_iter.slice(4)) - Math.min(...body_iter.slice(4)) + 1) / 5) ) : ""}`}>{[1,3,4,5,6,7,8].includes(idx) ? Math.round(iter).toLocaleString() : (idx === 2? `${iter} %`:iter)}</td>)}
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}