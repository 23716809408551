import React, {useState} from 'react'
import { Chart } from "react-google-charts";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Label from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ReMultiSelect from 'react-select';
import ReMultiSelectAnimated from 'react-select/animated';
import alasql from 'alasql';


function MetaAdsShopping({ViewedPage, shop_domain, app_url}) {
    ViewedPage(13);

    const [loading, setLoading] = useState(false);
    const [title_x_axis, setTitleXAxis] = useState("Clicks");
    const [title_y_axis, setTitleYAxis] = useState("Price");
    const [x_axis, setXAxis] = useState("Clicks");
    const [y_axis, setYAxis] = useState("Price");
    const [price_range, setPriceRange] = useState([["All","All"]]);
    const [ad_status_val, setAdStatusValue] = useState("All");
    const [scatter_overview_data, setScatterOverviewData] = useState(get_scatter_overview_data(overview_data, x_axis, y_axis, price_range, ad_status_val));
    const [table_overview_data, setTableOverviewData] = useState(get_table_overview_data(overview_data));
  

    return (
        <div className='main-container'>
            {!shop_domain?
                <div className='go_to_shopify_admin_div'>
                    <h5 style={{display:'inline-block'}}>!! Go to Shopify Admin and open our app !!</h5>
                </div>
                :
                <>
                    { !loading ?
                        <>
                            <div className='main-title mt-4'>
                                <h2>Creative Generative</h2>
                            </div>

                            <Container className='my-4'>
                                <Row className='justify-content-md-left' lg={5} xs={1}>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">X-Axis</Label>
                                        <ReMultiSelect 
                                            id="x_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            defaultValue={{ value: x_axis, label: x_axis }}
                                            options={[
                                                { value: 'Price', label: 'Price' },
                                                { value: 'Clicks', label: 'Clicks' },
                                                { value: 'Impr.', label: 'Impr.' },
                                                { value: 'CTR', label: 'CTR' },
                                                { value: 'Avg. CPC', label: 'Avg. CPC' },
                                                { value: 'Cost', label: 'Cost' },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setXAxis(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Y-Axis</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            defaultValue={{ value: y_axis, label: y_axis }}
                                            options={[
                                              { value: 'Price', label: 'Price' },
                                              { value: 'Clicks', label: 'Clicks' },
                                              { value: 'Impr.', label: 'Impr.' },
                                              { value: 'CTR', label: 'CTR' },
                                              { value: 'Avg. CPC', label: 'Avg. CPC' },
                                              { value: 'Cost', label: 'Cost' },
                                          ]}
                                            onChange={(selected_vals) => {
                                              setYAxis(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Cost Filter</Label>
                                        <ReMultiSelect 
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            defaultValue={[{ value: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All", label: price_range[0][0] !== "All"? price_range[0][0]+'-'+price_range[0][1] : "All" }]}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: '0-5000', label: '0-5000' },
                                                { value: '5000-10000', label: '5000-10000' },
                                                { value: '10000-50000', label: '10000-50000' },
                                                { value: '50000-100000', label: '50000-100000' },
                                            ]}
                                            onChange={(selected_vals) => {
                                                var temp_list = [];
                                                if(selected_vals.value !== "all"){
                                                    temp_list.push([selected_vals.value.split('-')[0], selected_vals.value.split('-')[1]]);
                                                    setPriceRange(temp_list);
                                                }
                                                else{
                                                    setPriceRange(["all","all"]);
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2'>
                                        <Label className="charts_filter_label">Ads Strength Filter</Label>
                                        <ReMultiSelect 
                                            id="y_axis"
                                            className='popup_filter_include_exclude'
                                            closeMenuOnSelect={false}
                                            components={ReMultiSelectAnimated()}
                                            defaultValue={{ value: ad_status_val, label: ad_status_val }}
                                            options={[
                                                { value: 'All', label: 'All' },
                                                { value: 'Not eligible', label: 'Not eligible' },
                                                { value: 'Eligible', label: 'Eligible' },
                                            ]}
                                            onChange={(selected_vals) => {
                                              setAdStatusValue(selected_vals.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className='p-2 d-flex align-items-end'>
                                        <Button className='d-flex align-items-center justify-content-center' style={{backgroundColor:"white",color:"#00d96d",border:"none",boxShadow:"0 2px 6px rgba(71,86,102,.05)",fontWeight:'500'}} onClick={() => {
                                            setLoading(true);
                                            setTimeout(()=> {
                                              setTitleXAxis(x_axis)
                                              setTitleYAxis(y_axis)
                                              setScatterOverviewData(get_scatter_overview_data(overview_data, x_axis, y_axis, price_range, ad_status_val))
                                              setLoading(false);
                                            }, 500)
                                        }}>Apply</Button>
                                    </Col>
                                </Row>
                            </Container>

                            <div className='cro-overview mb-5'>
                                { scatter_overview_data.length > 0 ?
                                    <Chart
                                        chartType="ScatterChart"
                                        width="100%"
                                        height="500px"
                                        data={scatter_overview_data}
                                        options={{
                                            pointSize:10,
                                            chartArea: {
                                                width: "70%",
                                                height: "80%"
                                            },
                                            hAxis: { 
                                                title:title_x_axis,
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            vAxis: {
                                              title:title_y_axis,
                                                viewWindow: {
                                                    min: 0
                                                },
                                                gridlines: {
                                                    color: '#e7e7e7',
                                                },
                                            },
                                            curveType: "function",
                                            tooltip: { isHtml: true },
                                            legend: {
                                              alignment: 'center',
                                              
                                            },
                                            series: [
                                              { color: 'red', labelInLegend: 'Not eligible' },
                                              { color: 'darkgreen', labelInLegend: 'Eligible' },
                                            ]
                                        }}
                                    />
                                    :
                                    "No data to Show."
                                }
                            </div>

                            <div className='cro-overview mb-5'>
                                { table_overview_data.length > 0 ?
                                    <Chart
                                    chartType="Table"
                                    width="100%"
                                    data={table_overview_data}
                                    options={{
                                        width: '100%',
                                        curveType: "function",
                                        legend: { position: "bottom" },
                                        allowHtml: true,
                                        cssClassNames:{
                                            tableCell :"meta_shopping_table_cell",
                                            headerCell :"meta_shopping_header_cell",
                                            tableRow :"meta_shopping_table_row", 
                                            selectedTableRow  : "meta_shopping_selected_table_row",
                                            hoverTableRow : "meta_shopping_hover_table_row",
                                            oddTableRow :"meta_shopping_table_row"
                                        }
                                    }}
                                    />
                                    :
                                    "No data to Show."
                                }
                            </div>
                        </>
                        :
                        <div className='main_conatiner_spinner'>
                            <Spinner animation="border"/>
                        </div>
                    }
                </>
            }
        </div>
    )

}

export default MetaAdsShopping

function jsonToArrayObject(res, graph_columns) {
  var columns = Object.keys(res[0]);
  var resultArray = res.map(function (obj) {
    var res_arr = [];
    for (let column_name of columns) {
      res_arr.push(obj[column_name]);
    }
    return res_arr;
  });
  resultArray.unshift(graph_columns)
 return resultArray;
}

function get_table_overview_data(overview_data){
  var res =  alasql('SELECT [Title], [Issues] FROM ? WHERE [Status] = "Not eligible"',[overview_data]);
  return jsonToArrayObject(res,["Product Title", "Issue"]);
}


function get_scatter_overview_data(overview_data, x_axis, y_axis, price_range, ad_status_val){
    var filtered_data = overview_data;

    if(price_range.length > 0){
      for(let price of price_range){
          if( price[0] !== "All"){
              filtered_data = alasql(`SELECT * FROM ? WHERE (([Cost] >= ${price[0]}) AND ([Cost] <= ${price[1]}))`, [filtered_data]);
          }
      }
  }

    if(ad_status_val !== "All"){
      filtered_data = alasql(`SELECT * FROM ? WHERE [Status] = '${ad_status_val}'`, [filtered_data]);
    }

    var res = alasql(`SELECT * FROM ?`, [filtered_data]);
    if(res.length > 0){
      var final_res = [[{label:x_axis,type:"number"},{label:"Not eligible",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Eligible",type:"number"},{type:'string','role': 'tooltip', 'p': {'html': true}}]]
      for(let obj of res ){
          final_res.push([
              parseFloat(`${obj[x_axis]}`.replace("$","")),
              
              obj["Status"] === "Not eligible" ? parseFloat(`${obj[y_axis]}`.replace("$","")) : null,
              `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Title"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Status : <b>${obj["Status"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Issues : <b>${obj["Issues"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Price : <b>${obj["Price"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Clicks : <b>${obj["Clicks"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Impr. : <b>${obj["Impr."]}</b></p>
                  <p style="margin:3px; font-size:medium;">CTR : <b>${obj["CTR"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Avg. CPC : <b>${obj["Avg. CPC"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Cost : <b>${parseFloat(obj["Cost"]).toFixed(1)}</b></p>
              </div>
              `,
                obj["Status"] === "Eligible" ? parseFloat(`${obj[y_axis]}`.replace("$","")) : null,
              `
              <div style="background-color:white; padding:20px; width:fit-content; border-radius:5px; box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)">
                  <h6 style="margin:3px;">${obj["Title"]}</h6>
                  <br>
                  <p style="margin:3px; font-size:medium;">Status : <b>${obj["Status"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Issues : <b>${obj["Issues"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Price : <b>${obj["Price"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Clicks : <b>${obj["Clicks"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Impr. : <b>${obj["Impr."]}</b></p>
                  <p style="margin:3px; font-size:medium;">CTR : <b>${obj["CTR"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Avg. CPC : <b>${obj["Avg. CPC"]}</b></p>
                  <p style="margin:3px; font-size:medium;">Cost : <b>${parseFloat(obj["Cost"]).toFixed(1)}</b></p>
              </div>
              `]);
      }
      console.log(final_res)
      return final_res;
    }
    return [[{label:x_axis,type:"number"},{label:"Not eligible",type:"number"}, {type:'string','role': 'tooltip', 'p': {'html': true}}, {label:"Eligible",type:"number"},{type:'string','role': 'tooltip', 'p': {'html': true}}]];
}

const overview_data = [
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39506470305849",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "90’S KID Style Cap",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$42.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39501791789113",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "AIRPLANE MODE // 006",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$38.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39506470436921",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "BAD ACID BLACK OVERSIZED T-SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$42.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39506470404153",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "BLUE OVERSIZED T-SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$32.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39506470371385",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "CLUB TENNIS SHORTS",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$42.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6562736767033_39506470338617",
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcRYLVzEtfvhBSXBNjls5WQScw6zR0jHYo25RDosRPoY3cbGtfJY34aRShzv6nPLuP6in823QPdq",
    "Title": "CRUISER // 004",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$32.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7133746561081_41705517187129",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcS4mO_Z6TsbQdMbVw9kICLi0u7aJTtE5kcEjeSwra5yQE93HABYVmext_ybTncws7EYmCjfsphy",
    "Title": "DREAMER Cap",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing value [gtin]",
    "Price": "$15.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6581895495737_39464302706745",
    "Image": "http://t1.gstatic.com/shopping?q=tbn:ANd9GcRqQ86mDag9ydBbNEMqQ-kxhcXzcmm_ITmUr-rFrljv-D2mZ3eyqsygsZd8Im03EWOlrvoUNKg",
    "Title": "DRIP AUTHORITY Cap",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock<br>Limited performance due to missing value [gtin]",
    "Price": "$12.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6932573126713_40949293350969",
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcRpgDiIJmIUD7yATy2YchUMfm_uJ9HwlL8UJm9FlauAGGN-LIyR_yvQmKCL82NYE8l6aUVi8nI",
    "Title": "Flight MVP Men's Woven Trousers",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$18.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6932573126713_40949293318201",
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcRpgDiIJmIUD7yATy2YchUMfm_uJ9HwlL8UJm9FlauAGGN-LIyR_yvQmKCL82NYE8l6aUVi8nI",
    "Title": "GENIE BLACK DENIM JACKET",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6932573126713_40949293285433",
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcRpgDiIJmIUD7yATy2YchUMfm_uJ9HwlL8UJm9FlauAGGN-LIyR_yvQmKCL82NYE8l6aUVi8nI",
    "Title": "Harry Potter: The Houses Men Oversized Hoodies",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$14.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6886650118201_40762253377593",
    "Image": "http://t1.gstatic.com/shopping?q=tbn:ANd9GcSxM0L9WNrTBa_eM3rPBGT6wblw-M2l3n-dkPOXFaD6ZIP7o7EWnIrp6S_y_-YAUnAuVjBh7IE",
    "Title": "Iron Man: Armoured Avenger Men Rugby Polo Sweatshirts",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$6.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1,
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcT7ZwCHigtBsN2-iZJJQyRV8k-VTvICmFlxLCexLk4c6XI1O1RbscRjPlkwoZjeWGEtKM6olKg",
    "Title": "MEN BLUE SLIM FIT FORMAL SHIRT",
    "Status": "Eligible",
    "Issues": "",
    "Price": "$35.00",
    "Clicks": 1479,
    "Impr.": 454399,
    "CTR": "0.33%",
    "Currency code": "USD",
    "Avg. CPC": 3.15,
    "Cost": 4662.38
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1002,
    "Image": "http://t2.gstatic.com/shopping?q=tbn:ANd9GcTrzimhbqFKOzuu52yObAjxgouM5V8dywK_dgNjALopyms1j3Q_TEVSpHuZsg1lz626jMzQ5_6Z",
    "Title": "MEN BLACK SLIM FIT FORMAL SHIRT",
    "Status": "Eligible",
    "Issues": "",
    "Price": "$35.00",
    "Clicks": 2415,
    "Impr.": 403090,
    "CTR": "0.6%",
    "Currency code": "USD",
    "Avg. CPC": 2.05,
    "Cost": 4947
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1002,
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcRRgWJN4IQtd_NBPyIcAk3ScB0WhgqWDsVqboEm-va2GLHoWoSRxwDlOGTH8psHQru6BjjZ5D8q",
    "Title": "MEN GREY SLIM FIT FORMAL SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$35.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1001,
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcTtUMq4-aY5whxk26F-6qaPwi27ek1Evj9hMfI8sk_5t-qZVS-YCh1sKo9mwHUf5MDrFnnqhy4",
    "Title": "MEN ORANGE SLIM FIT FORMAL SHIRT",
    "Status": "Eligible",
    "Issues": "",
    "Price": "$35.00",
    "Clicks": 6545,
    "Impr.": 928781,
    "CTR": "0.7%",
    "Currency code": "USD",
    "Avg. CPC": 2.36,
    "Cost": 15478.14
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1001,
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcTtUMq4-aY5whxk26F-6qaPwi27ek1Evj9hMfI8sk_5t-qZVS-YCh1sKo9mwHUf5MDrFnnqhy4",
    "Title": "MEN VIOLET  SLIM FIT FORMAL SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$35.00",
    "Clicks": 7,
    "Impr.": 2132,
    "CTR": "0.33%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 127676263,
    "Item ID": 1,
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcT7ZwCHigtBsN2-iZJJQyRV8k-VTvICmFlxLCexLk4c6XI1O1RbscRjPlkwoZjeWGEtKM6olKg",
    "Title": "MEN SKY BLUE SLIM FIT FORMAL SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$35.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6642087133241_39700790902841",
    "Image": "http://t1.gstatic.com/shopping?q=tbn:ANd9GcR_YKicgVrbGQVWbV6id-NZ39VarsHs4fm2hfGZRHQywg_wZLCepBMQ3wB3Dr8E7-7IAdKTqPGs",
    "Title": "MEN BLUE SOLID SLIM FIT FORMAL TWO PIECE SUIT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$17.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7222932144185_42246106185785",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcR02UZoFBRBSbLRN1u5-ry-8dD1KwDY40hJbUiWCBLHn25zJ-zpic5UCYR_ITk",
    "Title": "MEN GREEN SLIM FIT PRINT HALF SLEEVES CASUAL SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7222932144185_42246089441337",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcR02UZoFBRBSbLRN1u5-ry-8dD1KwDY40hJbUiWCBLHn25zJ-zpic5UCYR_ITk",
    "Title": "MEN BLUE SLIM FIT STRIPE FULL SLEEVES CASUAL SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7222932144185_42246106153017",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcR02UZoFBRBSbLRN1u5-ry-8dD1KwDY40hJbUiWCBLHn25zJ-zpic5UCYR_ITk",
    "Title": "MEN RED SOLID SLIM FIT FORMAL THREE PIECE SUIT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_102554632205_929431814157",
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcTdUPXh55SArdNCeH5nnmD0y43HqPWMpfMRaI3dm-v490JRLP4UQMr0BRCDxzsXba67D_GBXsg",
    "Title": "MEN ORANGE SOLID SLIM FIT FORMAL TWO PIECE SUIT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$3.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6909432234041_40860797730873",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcS0jEQefH_lwHqSff2yNTiocEP2gbqjzbGGCYkxv5OwCQty-HgNHGoHDJvj-Uq6b8F4wOzgQJ0G",
    "Title": "MEN WHITE TEXTURED FORMAL KURTA",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$14.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6909432234041_40860797698105",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcS0jEQefH_lwHqSff2yNTiocEP2gbqjzbGGCYkxv5OwCQty-HgNHGoHDJvj-Uq6b8F4wOzgQJ0G",
    "Title": "MEN KHAKI PRINT CASUAL KURTA",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$12.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6909432234041_40860797665337",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcS0jEQefH_lwHqSff2yNTiocEP2gbqjzbGGCYkxv5OwCQty-HgNHGoHDJvj-Uq6b8F4wOzgQJ0G",
    "Title": "Men's Woven Trousers",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$10.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_4806846906425_32902152978489",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcTqZGqjjPnpUHdeVOC2xTEtREFKZaq1BnMEwY6myP6GnY31SLANYmU7qiy22xVjFzyUWyO5ldU",
    "Title": "NOCTA Men's Fleece Trousers",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_4806846906425_41587906150457",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcTqZGqjjPnpUHdeVOC2xTEtREFKZaq1BnMEwY6myP6GnY31SLANYmU7qiy22xVjFzyUWyO5ldU",
    "Title": "Punisher: Vigilante Men Oversized Hoodies",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Out of stock",
    "Price": "$12.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7091833438265_41591084908601",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcQvuuErdCArfaWHcG5Sm5c5kfWjtegeLzyY0YD3cWHHfU3ECSGlUTLEih7Bb2M_GorW8MET5mJo",
    "Title": "Superman: Man of Steel Sweatshirts",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7091833438265_41591084875833",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcQvuuErdCArfaWHcG5Sm5c5kfWjtegeLzyY0YD3cWHHfU3ECSGlUTLEih7Bb2M_GorW8MET5mJo",
    "Title": "Tom and Jerry: Homerun Sweatshirts",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$16.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7091833438265_41591084843065",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcQvuuErdCArfaWHcG5Sm5c5kfWjtegeLzyY0YD3cWHHfU3ECSGlUTLEih7Bb2M_GorW8MET5mJo",
    "Title": "TWINFLAP OLIVE OVERSIZED SHIRT",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product",
    "Price": "$14.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7143421509689_41724373565497",
    "Image": "http://t0.gstatic.com/shopping?q=tbn:ANd9GcSGIbIqIpwnH1rQNermtuQY9xpUhaHIZS23q617RyZ3KrqlM4fuO7Fo02Pcm5ikP7R-1k6I2Gqm",
    "Title": "ULTIMATE 2 in 1 SHORTS",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$15.99",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6642347638841_39702181380153",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcQT3oY9qBPfFiINz46UbkSJh8G7b8BV4H1SArCf6fvFX1p2lRlM1pBTlOkiFUlqelkD-f7Gk09b",
    "Title": "UM CLASSIC WAYFARER // 002",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$48.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_7179952881721_41893376196665",
    "Image": "http://t1.gstatic.com/shopping?q=tbn:ANd9GcS2MYeu3rr4Iaui6ZOY61M1DKThgkTHPypOubVYPanT79HgZWLFfob2Wg0UpUXCY0z5sn-rrev8",
    "Title": "Wanderlust Hoodies",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$14.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  },
  {
    "Merchant ID": 9856607,
    "Item ID": "shopify_us_6642347638841_39702181347385",
    "Image": "http://t3.gstatic.com/shopping?q=tbn:ANd9GcQT3oY9qBPfFiINz46UbkSJh8G7b8BV4H1SArCf6fvFX1p2lRlM1pBTlOkiFUlqelkD-f7Gk09b",
    "Title": "WORKOUT HEAT.RDY PRINT SHORTS",
    "Status": "Not eligible",
    "Issues": "No campaigns advertising this product<br>Limited performance due to missing identifiers [gtin, mpn, brand]",
    "Price": "$32.00",
    "Clicks": 0,
    "Impr.": 0,
    "CTR": "0%",
    "Currency code": "USD",
    "Avg. CPC": 0,
    "Cost": 0
  }
];